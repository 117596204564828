import { ColumnDirective, ColumnsDirective, GridComponent, Group, GroupSettingsModel, Inject, Search, Toolbar, ToolbarItems, ExcelExport, Page, PageSettingsModel, Grid } from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import React, { useState, useEffect, useRef } from 'react'
import { DomainMapping } from "../../Models/DomainMapping";
import ManageToken from '../../Token';
import AddDomainModal from '../Modal/AddDomainModal';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import DeleteDomainModal from '../Modal/DeleteDomainModal';
import { Domain } from 'domain';
import { Breadcrumb, BreadcrumbItemDirective, BreadcrumbItemsDirective } from '@syncfusion/ej2-react-navigations';
import { BreadcrumbComponent } from '@syncfusion/ej2-react-navigations';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import { useLocation } from 'react-router-dom';
import eventBus from '../../EventBus';
import { useAuth } from 'react-oidc-context';
import NetIQUserRoles from '../../NetIQUserRoles';
import { DomainMappingSearch } from '../../Models/DomainMappingSearch';

function DomainRoleMappings(props: any) {

    const token = new ManageToken();
    const userRolesStorageVal = new NetIQUserRoles();
    const [domainRoles, setDomainRoles] = useState<Array<DomainMappingSearch>>([])
    const [isAdmin, setIsAdmin] = useState(false)
    const [hideSpinner, setHideSpinner] = useState(false)
    const [addDomainModalShow, setAddDomainModalShow] = React.useState(false);
    const [deleteDomainModalShow, setDeleteDomainModalShow] = React.useState(false);
    const [searchTerm, setSearchTerm] = React.useState("");
    const [isSearchEnabled, setSearchEnabled] = useState(false);
    const [domainToDelete, setDomainToDelete] = useState("");
    const [roleToDelete, setRoleToDelete] = useState("");

    const toolbarOptions: ToolbarItems[] = ['Search', 'ExcelExport'];
    let grid: GridComponent | null;
    const auth = useAuth();
    const { pathname } = useLocation();
    const mounted = useRef(false);
    let userRoles = userRolesStorageVal.GetUserRoles()

    useEffect(() => {
        mounted.current = true;
        eventBus.dispatch("domainRoleMapping", { message: pathname });
        console.log(userRoles)
        checkUserRole()
        return () => {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        console.log(pathname)
    }, [pathname])

    const toolbarClick = (args: ClickEventArgs) => {
        if (grid && args.item.id?.includes('excelexport')) {
            /* if (grid) {*/
            grid.excelExport();
        }
    }
    const pageOptions: PageSettingsModel = {
        pageSize: 50
    };
    /*, pageSizes: true pageSettings={{ pageCount: 10, pageSizes: true, pageSize: 2 }} */

    const groupOptions: GroupSettingsModel = {
        columns: ['domain'],
        showDropArea: false,
        showUngroupButton: false,
        captionTemplate: '#groupTemplate'
    };

    //const handleRefresh = () => {
    //    console.log('about to refresh');
    //    if (grid) {
    //        console.log('refreshing');
    //        grid.refresh();
    //    }
    //};
    //}

    const DeleteColTemplate = (props: any) => {
        return (<div className="row">
            <ButtonComponent cssClass='btn btn-primary btn btn-primary btn-sm'
                id="btnDelete" onClick={(e: any) => rowSelected(e)}>Delete</ButtonComponent>
        </div>);
    }

    //function domainDeleteModal(e: any) {
    //    var selectedRow = 0;
    //    if (e.currentTarget.offsetParent?.parentElement?.ariaRowIndex != undefined) {
    //        selectedRow = parseInt(e.currentTarget.offsetParent?.parentElement?.ariaRowIndex);
    //    }

    //    setDeleteDomainModalShow(true)
    //}

    function domainAddModal(event: any) {
        setAddDomainModalShow(true)
    }


    function onSearchChange(event: any) {
        setSearchTerm(event.target.value)
    }

    function handleSearchClick() {
        setHideSpinner(false)
        var getDomainMappingSearchUrl = process.env.REACT_APP_SERVER_URL_GET_DOMAIN_MAPPING_SEARCH ? process.env.REACT_APP_SERVER_URL_GET_DOMAIN_MAPPING_SEARCH.toString() : ""

        const getData = async () =>
            await fetch(getDomainMappingSearchUrl + searchTerm,
                {
                headers: { 'AUTHORIZATION': 'Bearer ' + token.GetToken() }
                }
            )
                .then(res => res.json())
                .then(
                
                    (result) => {                        
                        //let tempDomains = []

                        //for (let i = 0; i < result.length; i++) {
                        //    let ptDomain = new DomainMappingSearch()
                        //    //ptDomain.id = result[i].id
                        //    ptDomain.domain = result[i].domain
                        //    ptDomain.roleName = result[i].roleName
                        //    tempDomains.push(ptDomain)
                        //}
                        //if (result..isSuccess === true) {
                        //}

                        setDomainRoles(result)
                        setHideSpinner(true)
                    },
                    (error) => {
                        setHideSpinner(true)
                    }
                )
        setSearchEnabled(true)
        getData();
    }

    const rowSelected = (args: any) => {
        //  alert(args.data.domain)
        setDomainToDelete(args.data.domain)
        setRoleToDelete(args.data.roleName)
        setDeleteDomainModalShow(true)
    };

    function deleteConfirmation() {
        console.log(domainToDelete)

        let dupRoles = [...domainRoles]

        for (let i = 0; i < dupRoles.length; i++) {
            if (domainToDelete === dupRoles[i].domain) {
                dupRoles.splice(i, 1);
            }
        }

        let newArr = new Array<DomainMappingSearch>()

        for (let i = 0; i < dupRoles.length; i++) {
            let temp = new DomainMappingSearch();
            temp.domain = dupRoles[i].domain
            temp.modifiedBy = dupRoles[i].modifiedBy
            temp.roleName = dupRoles[i].roleName

            newArr.push(temp)
        }

        setDomainRoles(newArr)
    }

    useEffect(() => {
        console.log(domainRoles)
    }, [domainRoles])

    function checkUserRole() {
        if (userRoles?.includes("Domain_map_admin") === true) {
            setIsAdmin(true)
        }
        else setIsAdmin(false)
    }

    

    return (

        <div>
            { isAdmin &&
                <div>
                    <div style={{ padding: "1rem", marginTop: '1rem' }}>
                        <div className="row">
                            <div className="col-md-10">
                                <h2 className='title'>Domain Role Mapping</h2>
                                <p>'Domain Role Mapping' is used to assign an email domain to a role.</p>
                                <p>This will automatically assign this role to users, both new and existing, who have this email domain. A role gives users access to specific
                                    application(s). You can also remove a domain/role item which will remove that role from any existing users with that role, if indicated.</p>
                            </div>
                        </div >
                        <div className="row">
                            <div className="col-md-6">
                                <p style={{ fontWeight: 'bold' }} >Domain/Role Search</p>
                                <p>Enter a domain or role to find any corresponding records.</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <input type="text" style={{ marginBottom: "0.5rem" }} className="form-control form-control-md" placeholder="Enter a domain or role"
                                    name="name" onChange={onSearchChange} />
                            </div>
                            <div className="col-md-1">
                                <button className="btn btn-secondary" onClick={handleSearchClick}>Search</button>
                            </div>
                            <div className="col-md-1">
                                <button className="btn btn-secondary" onClick={(e) => domainAddModal(e)}>Add a Domain</button>
                            </div>
                        </div>
                        {isSearchEnabled &&
                            <div className="row">
                                <div className="col-md-10">
                                    <GridComponent dataSource={domainRoles} allowTextWrap={true} groupSettings={groupOptions} allowPaging={true} pageSettings={pageOptions}
                                        toolbar={toolbarOptions} allowExcelExport={true} toolbarClick={toolbarClick} 
                                        rowSelected={rowSelected} ref={g => grid = g} >
                                        
                                        <ColumnsDirective>
                                            <ColumnDirective headerTextAlign="Left" field='domain' width='200'
                                                headerText='Domain' textAlign="Left" />
                                            <ColumnDirective headerText="Role" field='roleName' width='200'
                                                headerTextAlign="Left" textAlign="Left" />
                                            <ColumnDirective headerText="Added By" headerTextAlign="Left" width='200'
                                                field='modifiedBy' textAlign="Left" />
                                            <ColumnDirective headerText="Date Added" headerTextAlign="Left" width='150'
                                                field='modifiedDate' textAlign="Left" />
                                            <ColumnDirective template={DeleteColTemplate} hideAtMedia="(min-width: 700px)"
                                                headerText="" headerTextAlign="Left" width='140' textAlign="Left" />
                                        </ColumnsDirective>
                                        <Inject services={[Group, Search, Toolbar, ExcelExport, Page]} />
                                    </GridComponent >
                                </div>
                                <div hidden={hideSpinner} className="spinner-border spinner-md text-primary" style={{ marginLeft: '37%', marginTop: '3%', position: 'absolute' }} role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        }
                    </div>

                    <div>
                        <AddDomainModal
                            show={addDomainModalShow}
                            onHide={() => setAddDomainModalShow(false)}
                            title="Add Domain"
                        />
                    </div>
                    <div>
                        <DeleteDomainModal
                            show={deleteDomainModalShow}
                            onHide={() => setDeleteDomainModalShow(false)}
                            title="Delete Domain"
                            domain={domainToDelete}
                            roleName={roleToDelete}
                            deleteConfirmation={deleteConfirmation}
                        />
                    </div>
                </div>
            }
            {isAdmin === false &&
                <div style={{ padding: "1rem", marginTop: '1rem' }}>
                    <label>Sorry, you do not have admin access.</label>
                </div>
            }
        </div>

    );
}
export default DomainRoleMappings