import { useEffect, useState, useRef } from 'react';
import DomainRoleMappings from './DomainRoleMappings';
import CustomerVendorAccounts from './CustomerVendorAccounts';
import { useNavigate, useLocation } from 'react-router-dom';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import { Breadcrumb, BreadcrumbItemDirective, BreadcrumbItemsDirective } from '@syncfusion/ej2-react-navigations';
import { BreadcrumbComponent } from '@syncfusion/ej2-react-navigations';
import { BreadCrumbs } from '../../Models/BreadCrumbs';
import eventBus from '../../EventBus';
import NetIQUserRoles from '../../NetIQUserRoles';

export default function Admin() {
    const [cards, setCards] = useState(true)
    const [domain, setDomain] = useState(false)

    const navigate = useNavigate();
    const userRolesStorageVal = new NetIQUserRoles();
    let userRoles = userRolesStorageVal.GetUserRoles()

    const { pathname } = useLocation();
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        eventBus.dispatch("adminTools", { message: pathname });

        return () => {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        console.log(pathname)
    }, [pathname])

    const navManageAcc = () => {
        navigate('/adminTools/customerVendorAccounts');
    };
    const navDomainMapping = () => {
        navigate('/adminTools/domainRoleMappings');
    };

    const ShowCompo = () => {
        if (domain === false) {
            setDomain(true)
            setCards(false)
        }
    }

    //function divClick(event: any): any  {
    //    console.log(event.currentTarget.id);
    //    if (event.currentTarget.id === "div1") {
    //        return (<div>Barkedl</div>)
    //    }
    //    //else {
    //    //    <customerVendorAccounts />
    //    //}
    //}

    return (
        <div>

            {cards &&
                <div style={{ padding: "1rem", marginTop: '1rem' }}>
                    <div className="row">
                        {
                            (userRoles?.includes("Domain_map_admin") === true) &&

                            <div className="col-md-3">
                                <div className="card card-bar-blue-dark" id="div1" onClick={navDomainMapping} style={{ width: "340px", cursor: 'pointer' }}>
                                    <h6 className="card-title" style={{ fontWeight: 'bold', marginTop: '1rem', marginLeft: '1rem' }} >Domain Role mapping</h6>
                                    <p className="card-text" style={{ marginLeft: '1rem', marginRight: '0.25rem' }} >
                                        Description of domain options and the functions within
                                    </p>
                                </div>

                            </div>
                        }
                        {
                            (userRoles?.includes("UserManager") === true) &&
                            <div className="col-md-3">
                                <div className=" card card-bar-blue-dark" id="div2" onClick={navManageAcc} style={{ width: "340px", cursor: 'pointer' }}>
                                    <h6 className="card-title" style={{ fontWeight: 'bold', marginTop: '1rem', marginLeft: '1rem' }} >View Assigned Accounts</h6>
                                    <p className="card-text" style={{ marginLeft: '1rem', marginRight: '0.25rem' }}>View and assign accounts to users</p>
                                </div>
                            </div>
                        }
                        {
                            (userRoles?.includes("Domain_map_admin") === false) && (userRoles?.includes("UserManager") === false) &&

                            <div style={{ padding: "1rem", marginTop: '1rem' }}>
                                <label>Sorry, you do not have admin access.</label>
                            </div>
                        }
                    </div>
                </div >
            }
        </div >
    );
}