import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ManageToken from '../../Token';
import { useEffect, useState } from 'react';
import { InputGroup } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

function RequestAssistanceModal(props: any) {
    const token = new ManageToken();
    const [mailBody, setMailBody] = useState("")
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event: any) => {

        var sendMailUrl = process.env.REACT_APP_SERVER_URL_POST_SEND_MAIL ? process.env.REACT_APP_SERVER_URL_POST_SEND_MAIL.toString() : ""

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            const request = {
                MailTo: props.mailTo,
                MailBody: mailBody,
            }

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'AUTHORIZATION': 'Bearer ' + token.GetToken() },
                body: JSON.stringify(request)
            };

            fetch(sendMailUrl, requestOptions)
                .then(response => response.json())
                .then(data => {
                    var responseData = data;
                    if (responseData == true) {
                        props.onHide()
                    }
                });
        }

        setValidated(true);

    };

    function handleMailBodyChange(event: any) {
        setMailBody(event.target.value);
    }


    return (

        <Modal className=""
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Request Assistance
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validated}>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="12" controlId="validationCustom03">
                            <Form.Label>Please fill out the form to request assistance with your registration.</Form.Label>
                            <Form.Control as="textarea" rows={3} onChange={handleMailBodyChange} required />
                            <Form.Control.Feedback type="invalid">
                                Please enter the details of the issue.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                   
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleSubmit} className="btn btn-primary">Send Mail</Button>
                <button className="btn btn-secondary" type="button" onClick={props.onHide}>Cancel</button>
                </Modal.Footer>
        </Modal>
    );
}

export default RequestAssistanceModal;


