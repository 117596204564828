import React, { useState, useEffect } from 'react'
import FilledCircle from '../GenericComponents/FilledCircle'
import { COLORS } from '../../values/colors';
import { ColumnDirective, ColumnsDirective, GridComponent, Group, GroupSettingsModel, Inject } from '@syncfusion/ej2-react-grids';
import '../../styles/appStyles.css'
import ManageToken from '../../Token';
import { useMediaQuery } from 'react-responsive';
import LearnMoreModal from '../Modal/LearnMoreModal';

function CurrentApplications(props: any) {

    interface CurentApplicationsRoles {
        id: number,
        assignmentToList: string,
        customName: string,
        customDescription: string,
        category: string,
    }

    const groupOptions: GroupSettingsModel = {
        columns: ['category'],
        showDropArea: false,
        showUngroupButton: false,
        captionTemplate: '#groupTemplate'
    };

    const token = new ManageToken();

    const [hideSpinner, setHideSpinner] = useState(false)

    const [currentApplications, setCurrentApplications] = useState<Array<CurentApplicationsRoles>>([])

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const [modalShow, setModalShow] = React.useState(false);
    const [learnMoreDesc, setLearnMoreDesc] = useState(false);

    function appNameColumnTemplate(props: any): any {
        if (props.LearnMore !== undefined && props.LearnMore !== null) {
            if (props.appurl !== undefined && props.appurl !== null) {
                return (
                    <div style={{ marginBottom: '-0.5rem' }} >
                        <p style={{ marginTop: "0.1rem" }}><a href={props.appurl} className='text-primary' style={{ fontWeight: 'bold' }}>{props.customName}</a>
                        </p>
                        <p style={{ marginTop: "-0.8rem" }}>{props.customDescription}
                            <a href="javascript:void(0)" onClick={(e) => showLearnMoreModal(props.LearnMore)} className='text-primary' style={{ fontWeight: 'bold', marginLeft: '0.2rem' }}>Learn More</a>
                            <a href="javascript:void(0)" onClick={(e) => showLearnMoreModal(props.LearnMore)}><span className="info"> i</span></a>
                        </p>
                    </div>
                )
            }
            else {
                <div style={{ marginBottom: '-0.5rem' }} >
                    <p style={{ marginTop: "0.1rem" }}><a><strong>{props.customName}</strong></a>
                    </p>
                    <p style={{ marginTop: "-0.8rem" }}>{props.customDescription}
                        <a href="javascript:void(0)" onClick={(e) => showLearnMoreModal(props.LearnMore)} className='text-primary' style={{ fontWeight: 'bold', marginLeft: '0.2rem' }}>Learn More</a>
                        <a href="javascript:void(0)" onClick={(e) => showLearnMoreModal(props.LearnMore)}><span className="info"> i</span></a>
                    </p>
                </div>
            }

        }
        else {
            if (props.appurl !== undefined && props.appurl !== null) {
                return (
                    <div>
                        <a href={props.appurl} className='text-primary'><strong>{props.customName}</strong></a>
                        <p>{props.customDescription}
                        </p>
                    </div>
                )
            }
            else {
                return (
                    <div>
                        <a href={props.appurl}><strong>{props.customName}</strong></a>
                        <p>{props.customDescription}
                        </p>
                    </div>
                )
            }
        }
    }

    function showLearnMoreModal(description: any) {
        setLearnMoreDesc(description)
        setModalShow(true)
    }

    function statusColumnTemplate(props: any): any {
        return (
            <div><FilledCircle bgColor={COLORS.Green} /></div>
        )
    }

    function getCurrentApplicationsData() {
        var getCurrentAppsUrl = process.env.REACT_APP_SERVER_URL_GET_CURRENT_APPLICATIONS ? process.env.REACT_APP_SERVER_URL_GET_CURRENT_APPLICATIONS.toString() : ""

        const getData = async () =>
            await fetch(getCurrentAppsUrl, {
                headers: { 'AUTHORIZATION': 'Bearer ' + token.GetToken() }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        setCurrentApplications(result.assignments)
                        setHideSpinner(true)
                    },
                    (error) => {
                        setHideSpinner(true)
                    }
                )
        
         getData();
    }

    useEffect(() => {

    }, [currentApplications])

    function getData() {
        getCurrentApplicationsData();
    }

    useEffect(() => {
        getData();
    }, [])

    useEffect(() => {
        getData();
    }, [props.refreshComponent])


    return (<div>
        {isDesktopOrLaptop && < div className="container">
            <div className='tableLayout'>
                <LearnMoreModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    title="Learn More" description={learnMoreDesc} />
                <div className="row">
                    <div className="col-md-12">
                        <GridComponent dataSource={currentApplications}
                            allowTextWrap={true} allowGrouping={true} groupSettings={groupOptions}>
                            <ColumnsDirective>
                                <ColumnDirective headerTextAlign="Left" template={appNameColumnTemplate}
                                    headerText='Application/Description' width='120' textAlign="Left" />
                                <ColumnDirective headerText="Access Status" template={statusColumnTemplate}
                                    headerTextAlign="Center" width='25' textAlign="Center" />
                                <ColumnDirective field='category' headerText="Category" headerTextAlign="Left" width='240'
                                    textAlign="Center" />
                            </ColumnsDirective>
                            <Inject services={[Group]} />
                        </GridComponent >
                    </div>
                    <div hidden={hideSpinner} className="spinner-border spinner-md text-primary" style={{ marginLeft: '37%', marginTop: '3%', position: 'absolute' }} role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </div>}
        {isTabletOrMobile &&
            <ul>
                {currentApplications !== undefined && currentApplications !== null && currentApplications.length > 0 && currentApplications.map((temp) => (
                    <div className="card bg-white" style={{ padding: "1rem", marginLeft: '-2rem', marginTop: '1rem' }} key={temp.id}>
                        <h6 className="card-title" style={{ fontWeight: 'bold' }} >{temp.customName}</h6>
                        <p className="card-text">{temp.customDescription}</p>
                    </div>
                ))}
                <div hidden={hideSpinner} className="spinner-border spinner-md text-primary" style={{ marginLeft: '33%', marginTop: '3%', position: 'absolute' }} role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </ul>
        }
    </div>);
}

export default CurrentApplications