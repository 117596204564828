import ReactDOM, { render } from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from "react-oidc-context";
import { registerLicense } from '@syncfusion/ej2-base';
import { createStore } from 'redux';
import { User } from 'oidc-client-ts';
import path from 'node:path/win32';

const store = createStore(() => ({

}));

// Registering Syncfusion license key
registerLicense('ORg4AjUWIQA/Gnt2VVhhQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkFhWX9bcnJWQmdbV0I=');

//Settings for NetIQ
var clientId = process.env.REACT_APP_SERVER_URL_CLIENT_ID ? process.env.REACT_APP_SERVER_URL_CLIENT_ID.toString() : ""
var clientSecret = process.env.REACT_APP_SERVER_URL_CLIENT_SECRET ? process.env.REACT_APP_SERVER_URL_CLIENT_SECRET.toString() : ""
var redirectUrl = process.env.REACT_APP_SERVER_URL_REDIRECT_URI ? process.env.REACT_APP_SERVER_URL_REDIRECT_URI.toString() : ""
//var redirectUrl = "https://webapp-reqaccess-web-dev.azurewebsites.net";
var authority = process.env.REACT_APP_SERVER_URL_AUTHORITY ? process.env.REACT_APP_SERVER_URL_AUTHORITY.toString() : ""
var authorizationEndpoint = process.env.REACT_APP_SERVER_URL_AUTHORIZATION_ENDPOINT ? process.env.REACT_APP_SERVER_URL_AUTHORIZATION_ENDPOINT.toString() : ""
var tokenEndpoint = process.env.REACT_APP_SERVER_URL_TOKEN_ENDPOINT ? process.env.REACT_APP_SERVER_URL_TOKEN_ENDPOINT.toString() : ""
var userInfoEndpoint = process.env.REACT_APP_SERVER_URL_USERINFO_ENDPOINT ? process.env.REACT_APP_SERVER_URL_USERINFO_ENDPOINT.toString() : ""
var postLogoutEndpoint = process.env.REACT_APP_SERVER_URL_POST_LOGOUT_ENDPOINT ? process.env.REACT_APP_SERVER_URL_POST_LOGOUT_ENDPOINT.toString() : ""

var oidcConfig = {
    //authority: "https://demo.duendesoftware.com/",
    //client_id: "interactive.public",
    //redirect_uri: "http://localhost:3000",
    //response_type: "code",
    //filterProtocolClaims: true,
    //loadUserInfo: true,
    //scope: "openid profile api",
    //automaticSilentRenew: false

    authority: authority,
    client_id: clientId,
    client_secret: clientSecret,
    redirect_uri: redirectUrl,
    response_type: "code",
    filterProtocolClaims: true,
    loadUserInfo: true,
    metadata: {
        authorization_endpoint: authorizationEndpoint,
        token_endpoint: tokenEndpoint,
        userinfo_endpoint: userInfoEndpoint
    },
    post_logout_redirect_uri: postLogoutEndpoint,
    scope: "openid CommScopeProfile",

    //automaticSilentRenew: false,
};

const onSignInCallBack = (_user: User | void): void => {
    console.log("onSignInCallBack");
    var pathname = new URL(window.sessionStorage.getItem('RedirectUri')?.toString()!).pathname;

    if (pathname === undefined || pathname === null || pathname === '') {
        pathname = window.location.pathname;
    }

    window.history.replaceState(
        {},
        document.title,
        pathname
    )
    window.sessionStorage.setItem("AuthorizationToken", (_user) ? _user?.access_token : "");
    window.location.reload();
}

const getAccessTokenExpiryTime = (number: undefined) => {
}

const onSignOutRedirect = () => {
    console.log()
}

ReactDOM.render(
    <AuthProvider {...oidcConfig} onSigninCallback={onSignInCallBack} onSignoutRedirect={onSignOutRedirect} revokeTokensOnSignout={true}>
        <App />
    </AuthProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
