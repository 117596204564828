import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function LearnMoreModal(props:any) {
    return (
        <Modal className=""
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    { props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    { props.description}
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>

    );
}
export default LearnMoreModal;