import React from 'react'

function ColoredLine(props:any) {
    return (
        <hr
            style={{
                color: props.color,
                backgroundColor: props.color,
                height: 3,
                border:0
            }}
        />
        )
}

export default ColoredLine;