import { useAuth } from 'react-oidc-context';

export default function LogoutUser() {
    const auth = useAuth();
    function logout() {
        var logoutURL = process.env.REACT_APP_LOGOUT_URL ? process.env.REACT_APP_LOGOUT_URL.toString() : "";
        window.sessionStorage.removeItem("AuthorizationToken");
        window.sessionStorage.clear();
        console.log(auth.user?.access_token);
        var revokeTokenApi = process.env.REACT_APP_SERVER_URL_REVOKETOKEN ? process.env.REACT_APP_SERVER_URL_REVOKETOKEN.toString() : "";
        fetch(revokeTokenApi + auth.user?.refresh_token, { headers: { "Authorization": "Bearer " + auth.user?.access_token } })
            .then(res => res.json())
            .then(result => {
                console.log(result);

                /*        setIsUserAuthorised(result)*/
            },
                (error) => {
                    console.log(error)
                })
        window.location.href = logoutURL;

    }
    return (<div style={{ width: '100%' }}><div style={{ float: 'right', paddingRight: '20px' }}><button className="btn btn-sm" title="Logout" onClick={logout} >Logout</button></div></div>);
}