import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom';
import eventBus from '../../EventBus';
import { BreadCrumbs } from '../../Models/BreadCrumbs';
import './BreadcrumbsStyles.css';

function Breadcrumbs(props: any) {

    var commscopeHomeUrl = process.env.REACT_APP_SERVER_URL_HOME ? process.env.REACT_APP_SERVER_URL_HOME.toString() : ""
    var commscopeMembershipUrl = process.env.REACT_APP_SERVER_URL_MEMBERSHIP ? process.env.REACT_APP_SERVER_URL_MEMBERSHIP.toString() : ""

    const [breadcrumbsVal, setBreadcrumbsVal] = useState<Array<BreadCrumbs>>([])

    const [location, setLocation] = useState("")
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;

        eventBus.on("userDetailsService", (data: any) =>
            setLocation(data.message)
        );

        eventBus.on("adminTools", (data: any) =>
            setLocation(data.message)
        );

        eventBus.on("domainRoleMapping", (data: any) =>
            setLocation(data.message)
        );

        eventBus.on("customerVendorAccounts", (data: any) =>
            setLocation(data.message)
        );

        return () => {
            mounted.current = false;
            eventBus.remove("userDetailsService", null);
            eventBus.remove("adminTools", null);
            eventBus.remove("domainRoleMapping", null);
            eventBus.remove("customerVendorAccounts", null);
        };

    }, []);

    useEffect(() => {
        console.log(location)
        if (location === '' || location==='/') {
            var tempBrArray = new Array<BreadCrumbs>()
            setBreadcrumbsVal(tempBrArray)
        }
        else if (location === '/adminTools') {
            var breadCrumbs = new BreadCrumbs()
            breadCrumbs.name = "Admin Tools"
            breadCrumbs.route = "/adminTools"
            var tempBrArray = new Array<BreadCrumbs>()
            tempBrArray.push(breadCrumbs)
            setBreadcrumbsVal(tempBrArray)
        }
        else if (location === '/adminTools/domainRoleMappings') {
            var breadCrumbs1 = new BreadCrumbs()
            breadCrumbs1.name = "Admin Tools"
            breadCrumbs1.route = "/adminTools"

            var breadCrumbs2 = new BreadCrumbs()
            breadCrumbs2.name = "Domain Role Mapping"
            breadCrumbs2.route = "/adminTools/domainRoleMapping"

            var tempBrArray = new Array<BreadCrumbs>()
            tempBrArray.push(breadCrumbs1)
            tempBrArray.push(breadCrumbs2)

            setBreadcrumbsVal(tempBrArray)
        }
        else if (location.toLowerCase() === '/admintools/customervendoraccounts') {
            var breadCrumbs1 = new BreadCrumbs()
            breadCrumbs1.name = "Admin Tools"
            breadCrumbs1.route = "/adminTools"

            var breadCrumbs2 = new BreadCrumbs()
            breadCrumbs2.name = "View Assigned Accounts"
            breadCrumbs2.route = "/adminTools/customerVendorAccounts"

            var tempBrArray = new Array<BreadCrumbs>()
            tempBrArray.push(breadCrumbs1)
            tempBrArray.push(breadCrumbs2)

            setBreadcrumbsVal(tempBrArray)
        }
    }, [location])

    return (
        //<div className='bg-gradient-blue-dark' style={{ height: '150px' }} >
        //    <div className='container'>
        //        <div className='col-md-9'>
        //            <ol className='breadcrumb breadcrumb-inverse'>
        //                <li className="breadcrumb-item"><a href="https://preprod.commscope.com">Home</a></li>
        //                <li className="breadcrumb-item"><a href="https://preprod.commscope.com/membership/">My CommScope</a></li>
        //                <li className="breadcrumb-item">Access Request</li>
        //            </ol>
        //        </div>
        //    </div>
        //</div>;

        <div>
            <div id="headerDiv" className=".band-xs-short bg-gradient-blue-dark text-black">
                <div className="hero-head">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-9">
                                <ol className="breadcrumb breadcrumb-inverse" id="breadCrumbList" style={{ marginTop: '20px' }}>
                                    <li className="breadcrumb-item"><a href={commscopeHomeUrl}>Home</a></li>
                                    <li className="breadcrumb-item"><a href={commscopeMembershipUrl}>My CommScope</a></li>

                                    {breadcrumbsVal !== undefined && breadcrumbsVal.length > 0 && 
                                        <li className="breadcrumb-item"><a href="/">Request Access</a></li>
                                    }

                                    {breadcrumbsVal.length === 0 &&
                                        <li className="breadcrumb-item"><a>Request Access</a></li>
                                    }

                                    {breadcrumbsVal !== undefined && breadcrumbsVal.length > 0 && breadcrumbsVal.map((temp: BreadCrumbs, index: number) => (
                                        (index < breadcrumbsVal.length - 1 &&
                                            <li className="breadcrumb-item"><a href={temp.route}>{temp.name} </a></li>
                                        )
                                    ))}
                                    {breadcrumbsVal !== undefined && breadcrumbsVal.length > 0 && breadcrumbsVal.map((temp: BreadCrumbs, index: number) => (
                                        (index === breadcrumbsVal.length - 1 &&
                                            <li className="breadcrumb-item"><a>{temp.name}</a></li>
                                        )
                                    ))}
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*<div className="hero hero-subpages hero-blue-dark mb-4">*/}
            {/*    <div className="hero-head">*/}
            {/*        <div className="container">*/}
            {/*            <div className="row">*/}
            {/*                <div className="col-md-8">*/}
            {/*                    <ol className="breadcrumb breadcrumb-inverse">*/}
            {/*                        <li className="breadcrumb-item"><a href="http://www.commscope.com/membership/">My CommScope</a></li>*/}
            {/*                        <li className="breadcrumb-item"><a href="/">RA</a></li>*/}
            {/*                        */}{/*{routes.map((route) => (*/}
            {/*                        */}{/*    <li className="breadcrumb-item"><a href={route.routeName}></a>{route.title}</li>*/}

            {/*                        */}{/*))}*/}
            {/*                        <li className="breadcrumb-item"><a href={routes}></a>{name}</li>*/}
            {/*                    </ol>                          */}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );

}
export default Breadcrumbs;