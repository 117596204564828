import * as React from 'react';
import { useAuth } from 'react-oidc-context';

export default class Header extends React.PureComponent {    
    state = {
        header : ""
    };
    constructor(props: any) {
        super(props);
        //this.header = "This is a test";
        this.DownloadHeader();
        
        
    }
    //const[header, setheader] = useState([]);
    DownloadHeader() {
        var headerUrl = process.env.REACT_APP_SERVER_URL_HEADER ? process.env.REACT_APP_SERVER_URL_HEADER.toString() : ""
        fetch(headerUrl)
            .then(res => res.text())
            .then(
                (result) => {
                    this.setState({ header: result });           
                },                
                (error) => {
                    //alert('err Header'+error.data);
                }
            )
    }
    public render() {      
        return (
            <div>
            <div className="content" dangerouslySetInnerHTML={{ __html: this.state.header }}>
                </div>
                {/*<LogoutComponent />*/}

            </div>
        );
    }
};

//const LogoutComponent = (props: any) => {
//        const auth = useAuth();
//    return <button onClick={() => {
//        auth.revokeTokens(["access_token"]);
//        window.sessionStorage.removeItem("AuthorizationToken");
//        window.location.href = "https://www.commscope.com/membership/account/logout/";
//        }}>Logout</button>
//}

