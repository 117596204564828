import { ColumnDirective, ColumnsDirective, GridComponent } from "@syncfusion/ej2-react-grids";
import React from "react";
import { useEffect, useRef, useState } from "react";
import { Alert, Col, Form, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import eventBus from "../../EventBus";
import { CustomerSalesOrg } from "../../Models/CustomerSalesOrg";
import { CustomerVendorMapping } from "../../Models/CustomerVendorMapping";
import { UserAdminSearch } from "../../Models/UserAdminSearch";
import NetIQUserRoles from "../../NetIQUserRoles";
import ManageToken from "../../Token";

export default function CustomerVendorAccounts() {
    const token = new ManageToken();
    const { pathname } = useLocation();
    const [isAdmin, setIsAdmin] = useState(false)
    const [isAccountAdded, setIsAccountAdded] = useState(false)
    const [isAccountAddedError, setIsAccountAddedError] = useState(false)
    const [isAccountAddedErrorMessage, setIsAccountAddedErrorMessage] = useState('')

    const mounted = useRef(false);
    const userRolesStorageVal = new NetIQUserRoles();
    let userRoles = userRolesStorageVal.GetUserRoles()
    const [hideSpinner, setHideSpinner] = useState(false)
    const [hideSpinnerSecond, setHideSpinnerSecond] = useState(false)
    const [UserAdminSearchResults, setUserAdminSearchResults] = useState<Array<CustomerVendorMapping>>([])
    const [UserAdminInherited, setUserAdminInherited] = useState<Array<CustomerVendorMapping>>([])
    const [displayGridView, setDisplayGridView] = useState(false);
    const [displayGridView2, setDisplayGridView2] = useState(false);
    const selectedPartners: number[] = [];
    const [inputEmail, setInputEmail] = useState("");
    const [inputVendorCustNum, setInputVendorCustNum] = useState("");
    const [VendorCustomerResults, setVendorCustomerResults] = useState<Array<CustomerVendorMapping>>([])
    const salesOrgRef = useRef("");
    const isVendor = useRef(false)
    const [alert, setAlert] = useState(false)
    const [areVendorCustomerResultsFound, setAreVendorCustomerResultsFound] = useState(false)

    useEffect(() => {
        mounted.current = true;
        eventBus.dispatch("customerVendorAccounts", { message: pathname });

        checkUserRole()

        return () => {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        console.log(pathname)
    }, [pathname])

    function checkUserRole() {
        if (userRoles?.includes("UserManager") === true) {
            setIsAdmin(true)
        }
        else setIsAdmin(false)
    }

    function AddAccountClick() {
        setAlert(false)
        setHideSpinnerSecond(false)
        var saveUrl = process.env.REACT_APP_SERVER_URL_POST_USER_ADMIN_ACCOUNTS_SAVE ? process.env.REACT_APP_SERVER_URL_POST_USER_ADMIN_ACCOUNTS_SAVE.toString() : ""
        var partnerTypeId = isVendor.current
        if (salesOrgRef.current === '') {
            if (VendorCustomerResults !== null && VendorCustomerResults.length > 0 && VendorCustomerResults[0].salesOrgs !== null
                && VendorCustomerResults[0].salesOrgs.length > 0) {
                salesOrgRef.current = VendorCustomerResults[0].salesOrgs[0].salesOrg
            }
        }
        const request = {
            UserName: inputEmail,
            CustomerNumber: inputVendorCustNum,
            PartnerType: isVendor.current === true ? 1 : 2,
            SalesOrg: salesOrgRef.current
        }

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
                , 'AUTHORIZATION': 'Bearer ' + token.GetToken()
            },
            body: JSON.stringify(request)
        };

        fetch(saveUrl, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data !== null && data.length > 0) {
                    setHideSpinnerSecond(true)
                    var responseData = data;

                    if (data[0].isSuccess === false) {
                        setHideSpinnerSecond(true)
                        setIsAccountAdded(false)
                        setIsAccountAddedError(true)
                        setIsAccountAddedErrorMessage(data[0].errorMessage)
                    }
                    else {

                        const tempRole = new Array<CustomerVendorMapping>()

                        for (let i = 0; i < data.length; i++) {
                            let pt = new CustomerVendorMapping()
                            let id = data[i].id
                            let soldTo = data[i].soldTo
                            let name = data[i].name
                            let salesOrg = data[i].salesOrg
                            pt.id = id
                            pt.soldTo = soldTo
                            pt.name = name
                            pt.salesOrg = salesOrg
                            tempRole.push(pt)
                        }
                        const NewNav = {
                            items: [...UserAdminSearchResults, ...tempRole].sort((a, b) =>
                                a.name > b.name ? 1 : -1,
                            )
                        };


                        setUserAdminSearchResults(NewNav.items)
                        setIsAccountAdded(true)
                        setIsAccountAddedError(false)
                    }

                }
                else {
                    setHideSpinnerSecond(true)
                    setIsAccountAdded(false)
                    setIsAccountAddedError(true)
                    setIsAccountAddedErrorMessage("Account not added. Please try again.")
                }

            });

        // setValidated(true);

    }

    function FindAccountClick() {
        salesOrgRef.current = ""
        setVendorCustomerResults([])
        //check if vendor or customer is selected.
        //if vendor call vendor function get vendor details for vendor num
        //if customer call  customer function get customer details for customer num
        //bind to grid
        setHideSpinnerSecond(false)
        setIsAccountAdded(false)
        setIsAccountAddedError(false)

        var getVendorCustomerUrl: string | null = null
        if (isVendor.current === true) //Vendor
        {
            getVendorCustomerUrl = process.env.REACT_APP_SERVER_URL_POST_USER_ADMIN_ACCOUNTS_VENDOR_LIST ? process.env.REACT_APP_SERVER_URL_POST_USER_ADMIN_ACCOUNTS_VENDOR_LIST.toString() : ""
        }
        else//Customer
        {
            getVendorCustomerUrl = process.env.REACT_APP_SERVER_URL_POST_USER_ADMIN_ACCOUNTS_CUSTOMER_LIST ? process.env.REACT_APP_SERVER_URL_POST_USER_ADMIN_ACCOUNTS_CUSTOMER_LIST.toString() : ""
        }

        const getData = async () =>
            await fetch(getVendorCustomerUrl + inputVendorCustNum
                ,{
                headers: { 'AUTHORIZATION': 'Bearer ' + token.GetToken() }
                }
            )
                .then(res => res.json())
                .then(

                    (result) => {
                        //debugger;
                        if (result.length === 1) {
                            if (result[0].name != null && result[0].name !== '') {
                                setVendorCustomerResults(result)
                                setAreVendorCustomerResultsFound(false)
                            }
                            else {
                                setVendorCustomerResults([])
                                setAreVendorCustomerResultsFound(true)
                            }
                        }
                        else if (result.length === 0) {
                            setVendorCustomerResults([])
                            setAreVendorCustomerResultsFound(true)
                        }


                        //setVendorCustomerResults(result)
                        setHideSpinnerSecond(true)
                    },
                    (error) => {
                        setHideSpinnerSecond(true)
                    }
                )
        setDisplayGridView2(true)
        getData();
    }

    function RemoveSelectedClick() {
        setHideSpinner(false)
        var deleteAccountsUrl = process.env.REACT_APP_SERVER_URL_POST_USER_ADMIN_ACCOUNTS_DELETE ? process.env.REACT_APP_SERVER_URL_POST_USER_ADMIN_ACCOUNTS_DELETE.toString() : ""

        const request = {
            selectedPartners: selectedPartners,
            userName: inputEmail,
        }

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
                , 'AUTHORIZATION': 'Bearer ' + token.GetToken()
            },
            body: JSON.stringify(request)
        };

        fetch(deleteAccountsUrl, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.isSuccess) {
                    //debugger;
                    setHideSpinner(true)
                    let dupRoles = [...UserAdminSearchResults]

                    for (let i = 0; i < dupRoles.length; i++) {
                        for (let j = 0; j < selectedPartners.length; j++) {

                            if (selectedPartners[j] === dupRoles[i].id) {
                                dupRoles.splice(i, 1);
                            }
                        }
                    }

                    let newArr = new Array<CustomerVendorMapping>()

                    for (let i = 0; i < dupRoles.length; i++) {
                        let temp = new CustomerVendorMapping();
                        temp.id = dupRoles[i].id
                        temp.name = dupRoles[i].name
                        temp.soldTo = dupRoles[i].soldTo
                        temp.salesOrg = dupRoles[i].salesOrg
                        newArr.push(temp)
                    }

                    setUserAdminSearchResults(newArr)
                }
            });
    }

    function handleSubmit() {
        setVendorCustomerResults([])
        setInputVendorCustNum("")
        setAlert(false)
        setIsAccountAdded(false)
        setIsAccountAddedError(false)
        setAreVendorCustomerResultsFound(false)

        var username = inputEmail
        var getVendorCustomerUrl: string | null = null
        if (isVendor.current === true) //Vendor
        {
            getVendorCustomerUrl = process.env.REACT_APP_SERVER_URL_POST_USER_ADMIN_ACCOUNTS_VENDOR_SEARCH ? process.env.REACT_APP_SERVER_URL_POST_USER_ADMIN_ACCOUNTS_VENDOR_SEARCH.toString() : ""
        }
        else if (isVendor.current === false) //Customer
        {
            getVendorCustomerUrl = process.env.REACT_APP_SERVER_URL_POST_USER_ADMIN_ACCOUNTS_CUSTOMER_SEARCH ? process.env.REACT_APP_SERVER_URL_POST_USER_ADMIN_ACCOUNTS_CUSTOMER_SEARCH.toString() : ""
        }
        setHideSpinner(false)
        const getData = async () =>
            //await fetch(getVendorCustomerUrl + username
            await fetch(getVendorCustomerUrl + "?userName=" + username
                ,{
                headers: { 'AUTHORIZATION': 'Bearer ' + token.GetToken() }
                }
            )
                .then(res => res.json())
                .then(

                    (result) => {
                        if (result.length > 0) {
                            //sort
                            const strAscending = [...result].sort((a, b) =>
                                a.name > b.name ? 1 : -1,
                            );

                            setUserAdminSearchResults(strAscending)

                            getInherited(getVendorCustomerUrl)
                        }
                        else {
                            setAlert(true)
                            setUserAdminSearchResults([])
                            getInherited(getVendorCustomerUrl)
                            //setAlertMsg(responseData.responseMessage[0])
                        }
                        setHideSpinner(true)
                    },
                    (error) => {
                        setHideSpinner(true)
                    }
                )
        setDisplayGridView(true);
        getData();
    }

    function getInherited(getVendorCustomerUrl: string | null) {
        const getData2 = async () =>
            await fetch(getVendorCustomerUrl + "?userName=@" + inputEmail.split('@')[1]
                ,{
                headers: { 'AUTHORIZATION': 'Bearer ' + token.GetToken() }
                }
            )
                .then(res => res.json())
                .then(

                    (result) => {
                        //debugger;
                        //sort
                        const strAscending = [...result].sort((a, b) =>
                            a.name > b.name ? 1 : -1,
                        );
                        setUserAdminInherited(strAscending)
                    },
                    (error) => {
                    }
                )
        getData2();
    }

    //const rowSelected = (args: any) => {
    //    selectedPartners.push(args.data.id)
    //    console.log('hello' + selectedPartners)
    //}
    //const rowDeselected = (args: any) => {
    //    console.log('Deselected')
    //    const index = selectedPartners.indexOf(args.data.id);
    //    console.log(index);

    //    if (index !== -1) {
    //        selectedPartners.splice(index, 1);
    //    }

    //    console.log('rowDeselected ' + selectedPartners)
    //}
    const handleEmailChange = (e: any) => {
        setInputEmail(e.target.value)
        // setInputEmail({ value: e.target.value })
    }
    const handleVendorCustIdChange = (e: any) => {
        setInputVendorCustNum(e.target.value)
    }
    const handleCheckChange = (e: any) => {
        if (e.target.value === '2') {
            isVendor.current = false
        }
        else {
            isVendor.current = true
        }
    }
    const salesOrgDDLChange = (event: any) => {
        salesOrgRef.current = event.target.value
    };
    function clearForm() {
        setAlert(false)
        setInputEmail("")
        setInputVendorCustNum("")
    }
    function onVenorCustomerResultsChecked(e: any, index: number, id: number) {
        if (e.target.checked == true) {
            selectedPartners.push(id)
        }
        else {
            const index = selectedPartners.indexOf(id);
            console.log(index);

            if (index !== -1) {
                selectedPartners.splice(index, 1);
            }
        }

    }
    return (
        <div>
            {isAdmin &&
                <div style={{ padding: "1rem", marginTop: '1rem' }} >
                    {/*<form>*/}
                    <div className="row">
                        <div className="col-md-10">
                            <h2 className='title'>View Assigned Accounts</h2><br />
                            <label>Enter a user email to see their assigned accounts</label><br />
                            <label>Email</label>
                            <input type="text" style={{ marginBottom: "0.5rem" }} width='40px' className="form-control form-control-md" name="name"
                                onChange={(e) => handleEmailChange(e)} autoFocus />
                        </div>
                    </div >
                    <br />
                    <div className="row">
                        <div className="col-md-6">
                            <input type="radio" value="2" name="radiocheck" onChange={(e) => handleCheckChange(e)} defaultChecked /> Customer <br />
                            <input type="radio" value="1" name="radiocheck" onChange={(e) => handleCheckChange(e)} /> Vendor<br />
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-md-6">
                            <button className="btn btn-primary" onClick={handleSubmit}>View Assigned Accounts</button>
                        </div>
                    </div>
                    <br />
                    {alert && <div className="row">
                        <div className="col-md-10">
                            <Alert variant="warning" >No Records Found</Alert>
                        </div>
                    </div>
                    }
                    <hr />
                    {/*  </form>*/}
                    {displayGridView && UserAdminInherited.length > 0 &&
                        <div className="row" style={{ width: '805px' }}>
                            {/*<div className="col-md-10">*/}
                            {/*    <GridComponent dataSource={UserAdminInherited} allowSelection={true} allowTextWrap={true} width='805px' >*/}
                            {/*        <ColumnsDirective>*/}
                            {/*            <ColumnDirective headerTextAlign="Left" field='name' width='150'*/}
                            {/*                headerText='Account Name' textAlign="Left" />*/}
                            {/*            <ColumnDirective headerText="Account ID" field='soldTo' width='90'*/}
                            {/*                headerTextAlign="Left" textAlign="Left" />*/}
                            {/*            {isVendor.current === false && <ColumnDirective headerText="Sales Org" field='salesOrg' width='90'*/}
                            {/*                headerTextAlign="Left" textAlign="Left" />}*/}
                            {/*        </ColumnsDirective>*/}
                            {/*    </GridComponent >*/}
                            {/*</div>*/}
                            <div className="table-responsive mt-4 ml-3 col-md-10">
                                <table className="table table-striped  table-bordered" >
                                    <thead>
                                        <tr >
                                            <th style={{ padding: "10px", paddingBottom: "5px", fontSize: "14px" }}>Account Name</th>
                                            <th style={{ padding: "10px", paddingBottom: "5px", fontSize: "14px" }}>Account ID</th>
                                            {isVendor.current === false && <th style={{ padding: "10px", paddingBottom: "5px", fontSize: "14px" }}>Sales Org</th>}
                                        </tr>
                                    </thead>
                                    <tbody >
                                        {UserAdminInherited.map((value, index) => {
                                            return (
                                                <tr style={{ textAlign: "left" }}>
                                                    <td className="pt-2 pb-2 col-3" style={{ padding: "10px", fontSize: "14px" }}>{value.name}</td>
                                                    <td className="pt-2 pb-2 col-3" style={{ padding: "10px", fontSize: "14px" }}>{value.soldTo}</td>
                                                    {isVendor.current === false && <td className="pt-2 pb-2 col-3" style={{ padding: "10px", fontSize: "14px" }}>{value.salesOrg}</td>}
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                    {
                        displayGridView &&
                        <div hidden={hideSpinner} className="spinner-border spinner-md text-primary" style={{ marginLeft: '37%', marginTop: '3%', position: 'absolute' }} role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    }
                    {displayGridView && UserAdminSearchResults.length > 0 &&
                        <div style={{ overflowY: 'scroll', height: '300px', width: '1020px', position: 'relative' }} className="row">
                            {/*<div className="col-md-10">*/}
                            {/*    <GridComponent dataSource={UserAdminSearchResults} allowSelection={true} allowTextWrap={true} width='100%' rowSelected={rowSelected} rowDeselected={rowDeselected} >*/}
                            {/*        <ColumnsDirective>*/}
                            {/*            <ColumnDirective type='checkbox' width='50' />*/}
                            {/*            */}{/*<ColumnDirective headerTextAlign="Left" template={SelectColTemplate}  width='20'*/}
                            {/*            */}{/*    headerText='' textAlign="Left" />*/}
                            {/*            <ColumnDirective headerTextAlign="Left" field='name' width='150'*/}
                            {/*                headerText='Account Name' textAlign="Left" />*/}
                            {/*            <ColumnDirective headerText="Account ID" field='soldTo' width='90'*/}
                            {/*                headerTextAlign="Left" textAlign="Left" />*/}
                            {/*            {isVendor.current === false && <ColumnDirective headerText="Sales Org" field='salesOrg' width='90'*/}
                            {/*                headerTextAlign="Left" textAlign="Left" />}*/}
                            {/*        </ColumnsDirective>*/}
                            {/*    </GridComponent >*/}
                            {/*</div>*/}
                            <div className="table-responsive mt-4 ml-3 col-md-10">
                                <table className="table table-striped  table-bordered">
                                    <thead>
                                        <tr >
                                            <th style={{ padding: "10px", paddingBottom: "5px", }}></th>
                                            <th style={{ padding: "10px", paddingBottom: "5px", fontSize: "14px" }}>Account Name</th>
                                            <th style={{ padding: "10px", paddingBottom: "5px", fontSize: "14px" }}>Account ID</th>
                                            {isVendor.current === false && <th style={{ padding: "10px", paddingBottom: "5px", fontSize: "14px" }}>Sales Org</th>}
                                        </tr>
                                    </thead>
                                    <tbody >
                                        {UserAdminSearchResults.map((value, index) => {
                                            return (
                                                <tr style={{ textAlign: "left" }}>
                                                    <td className="pt-2 pb-2 col-1" style={{ padding: "10px", fontSize: "14px" }}>
                                                        <input onChange={(e) => onVenorCustomerResultsChecked(e, index, value.id)} type="checkbox" /></td>
                                                    <td className="pt-2 pb-2 col-3" style={{ padding: "10px", fontSize: "14px" }}>{value.name}</td>
                                                    <td className="pt-2 pb-2 col-3" style={{ padding: "10px", fontSize: "14px" }}>{value.soldTo}</td>
                                                    {isVendor.current === false && <td className="pt-2 pb-2 col-3" style={{ padding: "10px", fontSize: "14px" }}>{value.salesOrg}</td>}
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                    {displayGridView && UserAdminSearchResults.length > 0 &&
                        <div className="row">
                            <div className="col-md-6">
                                <button className="btn btn-secondary" onClick={RemoveSelectedClick}>Remove selected Accounts from User</button>
                            </div>
                        </div>
                    }
                    <br />
                    {displayGridView &&
                        <div>
                            <div className="row">
                                <div className="col-md-10">
                                    <h4 className='title'>Add Accounts to a User</h4><br />
                                    <label>Enter Account ID</label>
                                    <input type="text" style={{ marginBottom: "0.5rem" }} className="form-control form-control-md" width="100px" name="vendorCustId"
                                        onChange={(e) => handleVendorCustIdChange(e)} value={inputVendorCustNum} placeholder="Account ID" />
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-md-6">
                                    <button className="btn btn-secondary" onClick={FindAccountClick}>Find Account</button>
                                </div>
                            </div>
                        </div>
                    }
                    <hr />
                    {
                        displayGridView2 &&
                        <div hidden={hideSpinnerSecond} className="spinner-border spinner-md text-primary" style={{ marginLeft: '37%', marginTop: '1%', position: 'absolute' }} role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    }
                    {areVendorCustomerResultsFound && <div className="row">
                        <div className="col-md-10">
                            <Alert variant="warning" >No Records Found</Alert>
                        </div>
                    </div>
                    }
                    {displayGridView2 && VendorCustomerResults.length > 0 &&
                        <div>
                            <label>Account Found:</label>

                            <div className="row" >

                                <div className="table-responsive mt-4 ml-3 col-md-10">
                                    <table className="table table-striped  table-bordered">
                                        <thead>
                                            <tr >
                                                <th style={{ padding: "10px", paddingBottom: "5px", fontSize: "14px", width: "400px" }}>Company </th>
                                                <th style={{ padding: "10px", paddingBottom: "5px", fontSize: "14px", width: "400px" }}>Address</th>
                                                <th style={{ padding: "10px", paddingBottom: "5px", fontSize: "14px" }}>Account ID</th>
                                                {isVendor.current === false && <th style={{ padding: "10px", paddingBottom: "5px", fontSize: "14px" }}>Sales Org</th>}
                                            </tr>
                                        </thead>
                                        <tbody >
                                            {VendorCustomerResults.map((value, index) => {
                                                let str = ""; let strSO: string = "";
                                                if (isVendor.current === false && value !== null) {
                                                    for (var i = 0; i < value.salesOrgs.length; i++) {
                                                        strSO += value.salesOrgs[i].salesOrg.toString() + ","
                                                    }
                                                    if (strSO.includes("5502")) {
                                                        str = "5502"
                                                    }
                                                    else if (strSO.includes("4712")) { str = "4712" }
                                                    else if (strSO.includes("1114")) { str = "1114" }
                                                    else { str = value.salesOrgs[0].salesOrg.toString() }

                                                    salesOrgRef.current = str
                                                }
                                                return (
                                                    <tr style={{ textAlign: "left" }}>
                                                        <td className="pt-2 pb-2 col-3" style={{ padding: "10px", fontSize: "14px" }}>{value.name}</td>
                                                        <td className="pt-2 pb-2 col-3" style={{ padding: "10px", fontSize: "14px" }}>{value.address}</td>
                                                        <td className="pt-2 pb-2 col-3" style={{ padding: "10px", fontSize: "14px" }}>{value.soldTo}</td>

                                                        {isVendor.current === false &&
                                                            <div className="col-md-10" style={{ paddingTop: "10px", fontSize: "14px" }}>
                                                                <select defaultValue={salesOrgRef.current}  className="custom-select" onChange={salesOrgDDLChange} >
                                                                    {value.salesOrgs?.length !== undefined && value.salesOrgs.map((temp, x) => {
                                                                        return (
                                                                            <option value={temp.salesOrg} >{temp.salesOrg + "-" + temp.description}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                                <br/>
                                                            </div>
                                                        }
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {/*    <div className="col-md-10">*/}
                            {/*        <GridComponent dataSource={VendorCustomerResults} allowTextWrap={true} width='70%'>*/}
                            {/*            <ColumnsDirective>*/}
                            {/*                <ColumnDirective headerTextAlign="Left" field='nameAddress' width='150'*/}
                            {/*                    headerText='Company & Address' textAlign="Left" />*/}
                            {/*                <ColumnDirective headerText="Account ID" field='soldTo' width='90'*/}
                            {/*                    headerTextAlign="Left" textAlign="Left" />*/}
                            {/*                <ColumnDirective headerText="Sales Org" width='90' template={salesOrgColumnTemplate}*/}
                            {/*                    headerTextAlign="Left" textAlign="Left" />*/}
                            {/*            </ColumnsDirective>*/}
                            {/*        </GridComponent >*/}

                            {/*        <div hidden={hideSpinnerSecond} className="spinner-border spinner-md text-primary" style={{ marginLeft: '37%', marginTop: '3%', position: 'absolute' }} role="status">*/}
                            {/*            <span className="sr-only">Loading...</span>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}

                            {/*<div hidden={hideSpinner} className="spinner-border spinner-md text-primary" style={{ marginLeft: '37%', marginTop: '3%', position: 'absolute' }} role="status">*/}
                            {/*        <span className="sr-only">Loading...</span>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            <div className="row">
                                <div className="col-md-6">
                                    <button className="btn btn-secondary" onClick={AddAccountClick}>Add Account</button>
                                </div>
                            </div>
                            {isAccountAdded &&
                                <div className="row mt-2">
                                    <div className="col-md-10">
                                        <Alert variant="success" >Account added successfully</Alert>
                                    </div>
                                </div>
                            }
                            {isAccountAddedError &&
                                <div className="row mt-2">
                                    <div className="col-md-10">
                                        <Alert variant="danger">{isAccountAddedErrorMessage}</Alert>
                                    </div>
                                </div>
                            }
                        </div>
                    }

                </div>
            }
            {
                isAdmin === false &&
                <div style={{ padding: "1rem", marginTop: '1rem' }}>
                    <label>Sorry, you do not have admin access.</label>
                </div>
            }
        </div>
    );
}