import { debug } from 'console';
import { useEffect, useState } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Roles } from '../../Models/Roles';
import ManageToken from '../../Token';

function AddDomainModal(props: any) {
    const token = new ManageToken();
    const [validated, setValidated] = useState(false);
    const [domain, setDomain] = useState("")
    const [role, setRole] = useState("")
    const [roleDn, setRoleDn] = useState("")
    const [alert, setAlert] = useState("")
    const [alertMsg, setAlertMsg] = useState("")
    const [rolesInfo, setRolesInfo] = useState<Array<Roles>>([])
    const [hideSpinner, setHideSpinner] = useState(true)


    function handleDomainChange(event: any) {
        setDomain(event.target.value);
    }

    function handleRoleChange(event: any) {
        setRole(event.target.value);
        var value = rolesInfo.filter(a => a.roleName == event.target.value);
        console.log(value[0].roleDn);
        setRoleDn(value[0].roleDn);
    }

    function clearForm() {
        setAlert("")
        setAlertMsg("")
        props.onHide()
    }

    useEffect(() => {
        getRoles()
    }, [])


    function getRoles() {

        const getData = async () => {
            var getAvailableAppsUrl = process.env.REACT_APP_SERVER_URL_ROLEINFO ? process.env.REACT_APP_SERVER_URL_ROLEINFO.toString() : ""
            await fetch(getAvailableAppsUrl, {
                headers: { 'AUTHORIZATION': 'Bearer ' + token.GetToken() }
            })
                .then(res =>
                    res.json()
                )
                .then(
                    (result) => {
                        let tempRole = []

                        for (let i = 0; i < result.length; i++) {
                            let ptRoles = new Roles()
                            let roleName = result[i].roleName
                            let roleDn = result[i].roleDn
                            ptRoles.roleName = roleName
                            ptRoles.roleDn = roleDn
                            tempRole.push(ptRoles)
                        }

                        //sort
                        const strAscending = [...tempRole].sort((a, b) =>
                            a.roleName > b.roleName ? 1 : -1,
                        );

                        setRolesInfo(strAscending);
                       
                    },
                    (error) => {
                        console.log(error)
                    }
                )
        }

        getData();
    }

    const handleSubmit = (event: any) => {
        setHideSpinner(false)
        var saveDomainUrl = process.env.REACT_APP_SERVER_URL_POST_DOMAIN_MAPPING_SAVE ? process.env.REACT_APP_SERVER_URL_POST_DOMAIN_MAPPING_SAVE.toString() : ""

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            const request = {
                domain: domain,
                roleName: role,
                roleDn: roleDn
            }

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'AUTHORIZATION': 'Bearer ' + token.GetToken() },
                body: JSON.stringify(request)
            };

            fetch(saveDomainUrl, requestOptions)
                .then(response => response.json())
                .then(data => {
                    var responseData = data;
                    if (responseData.isSuccess == true) {
                        setHideSpinner(true)
                        //props.onHide()
                        setAlert("success")
                        setAlertMsg(responseData.responseMessage[0])
                    }
                    else {
                        setHideSpinner(true)
                        setAlert("danger")
                        setAlertMsg(responseData.responseMessage[0])
                    }
                });
        }

        setValidated(true);

    };

    return (
        <Modal className=""
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/*<Form noValidate validated={validated} onSubmit={handleSubmit}>*/}
                <Form noValidate validated={validated} >
                    <Row className="mb-8">
                        <Form.Group as={Col} md="4" controlId="validationCustom03">
                            <Form.Control type="text" placeholder="Enter a domain" onChange={handleDomainChange} required />
                            <Form.Control.Feedback type="invalid">
                                Please enter domain.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="4" >
                            <Form.Control required as="select" type="select" onChange={handleRoleChange}>
                                <option value="">Select Role</option>
                                {rolesInfo !== undefined && rolesInfo.length > 0 &&
                                    rolesInfo.map(o =>
                                    (o.roleName.length > 0 &&
                                        <option key={o.roleDn} >{o.roleName} </option>
                                    )
                                    )}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                Please select role.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>

                    {/* <Button type="submit">Submit form</Button>*/}
                </Form>
                <div hidden={hideSpinner} className="spinner-border spinner-md text-primary" style={{ marginLeft: '37%', marginTop: '3%', position: 'absolute' }} role="status">
                    <span className="sr-only">Loading...</span>
                </div>
                <br />
                <div className="row">
                    <div className="col-md-10">
                        <Alert variant={alert} >{alertMsg}</Alert>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
               
                <Button onClick={handleSubmit} className="btn btn-primary">Add</Button>
                <Button onClick={clearForm}>Close</Button>
            </Modal.Footer>
        </Modal >

    );
}
export default AddDomainModal;


