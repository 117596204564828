import { CustomerSalesOrg } from "./CustomerSalesOrg";

export class CustomerVendorMapping {
    soldTo: string;
    name: string;
    partnerType: number;
    description: string;
    address: string;
    city: string;
    region: string;
    zipCode: string;
    country: string;
    salesOrg: string;
    nameAddress: string;
    id: number;
    //SalesOrgs: List<CustomerSalesOrg>;
    salesOrgs: CustomerSalesOrg[];

    constructor() {
        this.soldTo = "";
        this.name = "";
        this.partnerType = 0;
        this.description = "";
        this.address = "";
        this.zipCode = "";
        this.city = "";
        this.region = "";
        this.country = "";
        this.salesOrg = "";
        this.nameAddress = "";
        this.id = 0;
        this.salesOrgs = [];
        //  this.SalesOrgs = null;
    }
}