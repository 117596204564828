import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BreadCrumbs } from '../../Models/BreadCrumbs';
import DomainRoleMappings from './DomainRoleMappings';
import ManageAccountNumbers from './CustomerVendorAccounts';
import NetIQUserRoles from '../../NetIQUserRoles';

export default function Admin(props: any) {

    const navigate = useNavigate();
    const userRolesStorageVal = new NetIQUserRoles();
    let userRoles = userRolesStorageVal.GetUserRoles()

    const navAdminTools = () => {
        if ((userRoles?.includes("UserManager") === true) && (userRoles?.includes("Domain_map_admin") === false)) {
            navigate('/adminTools/customerVendorAccounts'); // only UserManager role
        }
        else if ((userRoles?.includes("UserManager") === false) && (userRoles?.includes("Domain_map_admin") === true)) {
            navigate('/adminTools/domainRoleMappings'); // only Domain_map_admin role
        }
        else
            navigate('/adminTools');
    };

    return (
        <div style={{ width: '100%' }}>
            <div style={{ float: 'right', paddingRight: '20px' }}>
                <button className="btn btn-sm" title="Admin" onClick={navAdminTools} >Admin</button>
            </div>
        </div>
    );
}