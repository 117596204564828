import React, { Component, useEffect, useRef, useState } from 'react'
import AvailableApplications from '../AvailableApplications/AvailableApplications';
import CurrentApplications from '../CurrentApplications/CurrentApplications';
import PendingRequests from '../PendingRequests/PendingRequests';
import './UserDetailsServiceStyles.css'
import ManageToken from '../../Token';
import { Breadcrumb, BreadcrumbItemDirective, BreadcrumbItemsDirective } from '@syncfusion/ej2-react-navigations';
import { BreadcrumbComponent } from '@syncfusion/ej2-react-navigations';
import { useLocation } from 'react-router-dom';
import eventBus from '../../EventBus';

function UserDetailsService(props: any) {
    const token = new ManageToken();
    const [userDetails, setUserDetails] = useState({
        ErrorMessage: null, IsSuccess: false, UserDetails: { dn: '', fullName: '', Uid: '' }
    })
    const [refreshComponent, setRefreshComponent] = useState(false)
    const [showMessage, setShowMessage] = useState(false)

    const [pendingRequests, setPendingRequests] = useState([])

    const { pathname } = useLocation();
    const mounted = useRef(false);
    useEffect(() => {
        mounted.current = true;
        eventBus.dispatch("userDetailsService", { message: pathname });

        return () => {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        console.log(pathname)
    }, [pathname])

    function updateFromAvailableApps(pendingData: any) {
        setRefreshComponent(true)
    }

    function updateFromPendingRequests(pendingRequests: any) {
        setPendingRequests(pendingRequests)

    }

    //function getUserDetails() {
    //    var getUserDetailsUrl = process.env.REACT_APP_SERVER_URL_GET_USER_DETAILS ? process.env.REACT_APP_SERVER_URL_GET_USER_DETAILS.toString() : ""
    //    const getData = async () =>
    //        await fetch(getUserDetailsUrl, {
    //            headers: { 'AUTHORIZATION': 'Bearer ' + token.GetToken() }
    //        })
    //            .then(res => res.json())
    //            .then(
    //                (result) => {
    //                    setUserDetails(result);
    //                },
    //                (error) => {
    //                    console.log(error);
    //                }
    //            )
    //    if (token.GetToken() !== undefined || token.GetToken() !== null || token.GetToken() !== "") {
    //        getData();
    //    }
    //}

    //useEffect(() => {
    //    console.log(userDetails)
    //}, [userDetails])

    //useEffect(() => {
    //    getUserDetails();
    //}, [])

    return (
        <div >
            {/*<BreadcrumbComponent enableNavigation={true}>*/}
            {/*    <BreadcrumbItemsDirective>*/}
            {/*        <BreadcrumbItemDirective text="Home" url="https://ej2.syncfusion.com/home/react.html#platform" />*/}
            {/*        <BreadcrumbItemDirective text="My CommScope" url="https://ej2.syncfusion.com/react/demos/#/material/grid/overview/" />*/}
            {/*        <BreadcrumbItemDirective text="Request Access" url="./breadcrumb/default" />*/}
            {/*    </BreadcrumbItemsDirective>*/}
            {/*</BreadcrumbComponent>*/}
            {showMessage == true &&
                <div>
                    <PendingRequests refreshComponent={refreshComponent}
                        updateFromPendingRequests={updateFromPendingRequests} />

                    <nav style={{ marginLeft: '8rem', marginRight: '8rem', marginTop: '2rem' }}>
                        <div className="nav nav-tabs tabs" id="nav-tabs" role="tablist">
                            <a className="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-availableapps" role="tab" aria-controls="available apps" aria-expanded="true">Available Applications</a>
                            <a className="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-currentapplications" role="tab" aria-controls="current applications" aria-expanded="false">Current Applications</a>
                        </div>
                    </nav>

                    <div className="tab-content" id="nav-tabContent" style={{ marginLeft: '8rem', marginRight: '8rem' }}>
                        <div className="tab-pane fade active show" id="nav-availableapps" role="tabpanel" aria-labelledby="nav-home-tab" aria-expanded="true">
                            <div>
                                <AvailableApplications id="availableApps" updateFromAvailableApps={updateFromAvailableApps}
                                    pendingRequests={pendingRequests} />
                            </div>
                        </div>
                        <div className="tab-pane fade" id="nav-currentapplications" role="tabpanel" aria-labelledby="nav-profile-tab" aria-expanded="false">
                            <CurrentApplications id="currentApps" refreshComponent={refreshComponent} />
                        </div>
                    </div>
                </div>
            }
            {showMessage === false &&
                <div className="card col-md-4 mt-4 ml-4">
                    <div className="card-body">
                        <h4 className="card-title" style={{ color:"#0039C1" }}>Coming Soon...</h4>
                        <p className="card-text">We are working on Request Access and this will be out soon!</p>
                    </div>
                </div>
            }
        </div>
    );
}

export default UserDetailsService