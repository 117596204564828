import React, { Component, useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
//import 'bootstrap/dist/css/bootstrap.min.css';
import UserDetailsService from './components/Services/UserDetailsService';
import Banner from './components/Breadcrumbs/Breadcrumbs';
import './App.css';
import { AuthProvider, useAuth } from "react-oidc-context";
import LogoutUser from './components/Logout/Logout';
import Admin from './components/Admin/Admin';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import DomainRoleMappings from './components/Admin/DomainRoleMappings';
import CustomerVendorAccounts from './components/Admin/CustomerVendorAccounts';
import AdminTools from './components/Admin/AdminTools';
import { Breadcrumb, BreadcrumbItemDirective, BreadcrumbItemsDirective } from '@syncfusion/ej2-react-navigations';
import { BreadcrumbComponent } from '@syncfusion/ej2-react-navigations';
import { enableRipple } from '@syncfusion/ej2-base';
import { BreadCrumbs } from './Models/BreadCrumbs';

enableRipple(true);
function App(props: any) {
    const [show, setShow] = useState(false)
    const [newBreadCrumbs, setNewBreadCrumbs] = useState<Array<BreadCrumbs>>([])

    const auth = useAuth();

    useEffect(() => {
        let userUrl = window.location.href
        //setCookie('browserPath', userUrl, {path:'/'})
    }, [])

    function setLatestBreadCrumbs(newValues: Array<BreadCrumbs>) {
        setNewBreadCrumbs(newValues)
    }
    //useEffect(() => {
    //    console.log(props.navigation.state.routeName)
    //}, [props.navigation])

    switch (auth.activeNavigator) {
        case "signinSilent":
            return <div>Signing you in...</div>;
        case "signoutRedirect":
            return <div>Signing you out...</div>;
    }

    if (auth.isLoading) {
        return <div>Loading...</div>;
    }

    if (auth.error) {
        return <div>Oops... {auth.error.message}</div>;
    }

    //const ShowCompo = () => {
    //    if (show == false) {
    //        setShow(true)
    //    }
    //}


    //if (auth.isAuthenticated || true) {
    if (auth.isAuthenticated) {

        var roles = new Array(auth.user?.profile["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"])

        window.sessionStorage.setItem("AuthorizationToken", (auth.user) ? auth.user?.access_token : "");
        window.sessionStorage.setItem("LoggedInUserRoles", roles.toString() ? roles.toString() : "");


        return (
            <div>
                {/*<button title="Logout" onClick={() => {*/}
                {/*    auth.removeUser();*/}
                {/*}} />*/}
                <Header />
                <Banner />

                <LogoutUser />
                <Router >
                    <Routes >
                        <Route path="/adminTools/domainRoleMappings" element={<DomainRoleMappings />} />
                        <Route path="/adminTools/customerVendorAccounts" element={<CustomerVendorAccounts />} />
                        <Route path="/adminTools" element={<AdminTools />} />
                        {(roles.toString().includes("Domain_map_admin") || roles.toString().includes("UserManager")) &&
                            <Route path="/" element={<Admin />} />
                        }
                    </Routes>
                    <Routes>
                        <Route path="/" element={<UserDetailsService />} />
                    </Routes>
                </Router>

                <Footer />

            </div>
        );
    }

    function callAuthentication() {
        window.sessionStorage.setItem("RedirectUri", window.location.href);
        auth.signinRedirect()
        return true;
    }
    return (
        <div className="App">
            {callAuthentication()}
        </div>
    );
}
export default App;

