import * as React from 'react';
import { connect } from 'react-redux';
//import { RouteComponentProps } from 'react-router';
//import { ApplicationState } from '../store';
//import * as CounterStore from '../store/Counter';



export default class Footer extends React.PureComponent {
    state = {
        footer: ""
    };
    constructor(props:any) {
        super(props);
        //this.header = "This is a test";
        this.DownloadFooter();


    }
    //const[header, setheader] = useState([]);
    DownloadFooter() {
        var footerUrl = process.env.REACT_APP_SERVER_URL_FOOTER ? process.env.REACT_APP_SERVER_URL_FOOTER.toString() : "";
        fetch(footerUrl)
            .then(res => res.text())
            .then(
                (result) => {
                    this.setState({ footer: result });
                },
                (error) => {
                    //alert('err Footer' + error.data);
                }
            )
    }
    public render() {
        return (
            <div className="content" dangerouslySetInnerHTML={{ __html: this.state.footer }}></div>
        );
    }
};

