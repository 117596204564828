import React, { useState, useMemo, useEffect, useRef } from 'react'
import FilledCircle from '../GenericComponents/FilledCircle'
import { COLORS } from '../../values/colors';
import { ColumnDirective, ColumnsDirective, GridComponent, Group, GroupSettingsModel, Inject } from '@syncfusion/ej2-react-grids';
import '../../styles/appStyles.css'
import '../../styles/toolTip.css'
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import ManageToken from '../../Token';
import { useMediaQuery } from 'react-responsive';
import LearnMoreModal from '../Modal/LearnMoreModal';
import { Button, Modal, Tooltip } from 'react-bootstrap';
import RequestAssistanceModal from '../Modal/RequestAssistance';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { AvailableApplication } from '../../Models/AvailableApplication';

function AvailableApplications(props: any) {
    interface Roles {
        id: number,
        roleDn: string,
        applicationName: string,
        description: string,
        requiresIVR: boolean,
        category: string,
        sourceDn: string,
        userDn: string,
        netIQSourceDn: string,
        appUrl: string,
        bundledRole: string,
        bundledRolesList: [],
        excludedRole: string,
        excludedRolesList: [],
        isRequestable: boolean,
        ivrInfo: string,
        learnMore: string,
        roleDNAppName: string,
        roleDNLevel: string,
        supportEmail: string

    }
    interface Assignments {
        id: string,
        assignmentToList: [AssignmentToList],
        effectiveDate: string,
        expiryDate: string
    }
    interface AssignmentToList {
        assignedToDn: string,
        subtype: string
    }
    interface RequestAccess {
        reason: string,
        assignments: [Assignments],
        ivr: string
    }
    interface RequestAccessResponse {
        ErrorMessage: string,
        IsSuccess: boolean,
        Succeeded: RequestAccessSucceeded
    }
    interface RequestAccessSucceeded {
        id: string
    }

    interface CRMSupportPortal {
        ivr: string,
        contact: CRMSupportPortalContact,
        origin: "MyCommScope"
    }

    interface CRMSupportPortalContact {
        jobTitle: string,
        firstName: string,
        lastName: string,
        emailAddress: String
    }

    interface CRMSupportPortalResponse {
        success: boolean,
        responseMessage: string,
        customerMessage: string
    }

    interface PeopleSoftCustomerValidation {
        IVR: string
    }

    interface PeopleSoftCustomerValidationResponse {
        IsSuccess: boolean,
        ErrorMessage: string
    }

    const token = new ManageToken();
    const [error, setError] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)
    const [userDetailsInfo, setUserDetailsInfo] = useState({
        UserDetails: { dn: "" }
    })
    const availableAppsRef = useRef<Array<Roles>>([])
    const [availableApplications, setAvailableApplications] = useState<Array<Roles>>([])

    const [selectedRoleDn, setSelectedRoleDn] = useState("");

    const [hideSpinner, setHideSpinner] = useState(false)


    const [request, setRequest] = useState<RequestAccess>()
    const [modalShow, setModalShow] = React.useState(false);
    const [requestAssistanceModalShow, setRequestAssistanceModalShow] = React.useState(false);
    const [requestAssistanceSupportMail, setRequestAssistanceSupportMail] = React.useState("");

    const [ivrAlertMessage, setIvrAlertMessage] = React.useState("");
    const [ivrAlertMessageShow, setIvrAlertMessageShow] = React.useState(false);


    const [smAlert, setSmAlert] = useState(false);
    const [smAlertMessageTitle, setSmAlertMessageTitle] = useState("");
    const [smAlertMessageDesc, setSmAlertMessageDesc] = useState("");
    const [learnMoreDesc, setLearnMoreDesc] = useState(false);

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    var ivrNumber = "";

    const handleIvrChange = (event: any) => {
        ivrNumber = event.target.value;
        console.log('value is:', event.target.value);
    };

    interface CurentApplicationsRoles {
        id: number,
        assignmentToList: string,
        customName: string,
        customDescription: string,
        category: string,
    }

    const [currentApplications, setCurrentApplications] = useState<Array<CurentApplicationsRoles>>([])

    const groupOptions: GroupSettingsModel = {
        columns: ['category'],
        showDropArea: false,
        showUngroupButton: false,
        captionTemplate: '#groupTemplate'
    };

    useEffect(() => {
        console.log(ivrAlertMessage)
    }, [ivrAlertMessage, ivrAlertMessageShow])

    function appNameColumnTemplate(props: any): any {
        if (props.learnMore !== undefined && props.learnMore !== null) {
            return (
                <div>
                    <a><strong>{props.applicationName}</strong></a>
                    <p style={{}}>{props.description}
                        <br />
                        <a href="javascript:void(0)"
                            onClick={(e) => showLearnMoreModal(props.learnMore)} className='text-primary' style={{ fontWeight: 'bold' }}>Learn More</a>
                        <a href="javascript:void(0)" onClick={(e) => showLearnMoreModal(props.learnMore)}><span className="info"> i</span></a>
                    </p>
                </div>
            )
        }
        else {
            return (
                <div>
                    <a><strong>{props.applicationName}</strong></a>
                    <p style={{ marginTop: "2%" }}>{props.description}
                    </p>

                </div>
            )
        }
    }

    function showLearnMoreModal(description: any) {
        setLearnMoreDesc(description)
        setModalShow(true)
    }


    function statusColumnTemplate(props: any): any {
        return (
            <div className="row">

                <FilledCircle bgColor={COLORS.Red} />
                <button type="button" className="btn btn-secondary"
                    style={{ marginLeft: '0.7rem', marginTop: "-0.5rem", marginBottom: '0.5rem' }}
                    onClick={(e) => submitRequest(props.roleDn, e, props.requiresIVR, props.userDn)}>REQUEST ACCESS</button>
            </div>
        )
    }

    function ivrColumnTemplate(props: any): any {
        const requiresIVR = props.requiresIVR
        if (requiresIVR == true) {
            return (
                <div className="row" style={{ marginBottom: "0.5rem" }}>
                    <div className="col-md-12">
                        <OverlayTrigger
                            key='top'
                            placement='top'
                            overlay={
                                <Tooltip id={`tooltip-top`}>
                                    {props.IvrInfo}
                                </Tooltip>
                            }>
                            <div className='tooltipLayout'>
                                <label style={{ fontSize: '12px' }}>IVR #</label>
                            </div>
                        </OverlayTrigger>
                        <input type="text" style={{ marginBottom: "0.5rem" }} className="form-control form-control-sm"
                            name="name" onChange={handleIvrChange} />
                        <button style={{ display: 'contents' }} type="button" className="btn btn-link"
                            onClick={(e) => requestAssistance(props.supportEmail, e)}>Request Assistance</button>
                    </div>
                </div>
            )
        }
    }

    function requestAssistance(e: any, event: any) {
        console.log(e);
        setRequestAssistanceSupportMail(e.toString())
        setRequestAssistanceModalShow(true)
    }

    function submitRequest(e: any, event: any, requiresIVR: any, userDn: any) {

        console.log(ivrNumber);
        event.currentTarget.disabled = true;

        if (requiresIVR == true) {
            if (ivrNumber !== "") {
                submitRequestForAccess(e, requiresIVR, userDn);
            }
            else {
                setSmAlertMessageTitle("Invalid IVR");
                setSmAlertMessageDesc("Please enter the IVR Number");
                setSmAlert(true);
            }
        }
        else {
            submitRequestForAccess(e, requiresIVR, userDn);
        }

        event.currentTarget.disabled = false;
    }

    function submitRequestForAccess(roleDn: string, requiresIVR: any, userDn: any) {
        if (requiresIVR == true) {
            if (roleDn.toLowerCase().includes("askarris")) {
                checkIvrForPeopleSoft(roleDn, userDn)
            }
            else {
                checkIvrForSupportPortal(roleDn, userDn)
            }
        }
        else {
            if (roleDn.toLowerCase().includes("bbn_techpubs")) {
                validateAndAssignTechPubs(roleDn, userDn)
            }
            else {
                submitRequestForNonIvr(roleDn, userDn)
            }
        }
    }

    function submitRequestForNonIvr(roleDn: any, userDn: any) {
        const assignmentToList: AssignmentToList = {
            assignedToDn: userDn,
            subtype: 'user'
        }

        const assignments: Assignments = {
            id: roleDn,
            assignmentToList: [assignmentToList],
            effectiveDate: '',
            expiryDate: ''
        }

        const request: RequestAccess = {
            reason: 'Assigned Roles via Request Access Application',
            assignments: [assignments],
            ivr: ivrNumber
        }

        var submitRequestUrl = process.env.REACT_APP_SERVER_URL_POST_REQUEST_ACCESS ? process.env.REACT_APP_SERVER_URL_POST_REQUEST_ACCESS.toString() : ""

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'AUTHORIZATION': 'Bearer ' + token.GetToken() },
            body: JSON.stringify(request)
        };

        fetch(submitRequestUrl, requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log();
                var responseData = data as RequestAccessResponse;
                if (responseData.IsSuccess == true) {
                    getCurrentApplicationsData(roleDn, userDn);
                }
                else {
                    setSmAlertMessageTitle("Error");
                    setSmAlertMessageDesc(data.ErrorMessage);
                    setSmAlert(true);
                }
            });
    }

    function checkIvrForPeopleSoft(roleDn: string, userDn: any) {

        var fullName = props.userDetails.UserDetails.fullName;

        const pSoftCustomer: PeopleSoftCustomerValidation = {
            IVR: ivrNumber.toString()
        }

        const pSoftCustomerResponse: PeopleSoftCustomerValidationResponse = {
            IsSuccess: false,
            ErrorMessage: ""
        }

        var submitRequestUrl = process.env.REACT_APP_SERVER_URL_POST_PEOPLESOFT_CUSTOMER_VALIDATION ? process.env.REACT_APP_SERVER_URL_POST_PEOPLESOFT_CUSTOMER_VALIDATION.toString() : ""
        submitRequestUrl = submitRequestUrl + "?ivrNumber=" + ivrNumber.toString()

        fetch(submitRequestUrl, {
            headers: { 'AUTHORIZATION': 'Bearer ' + token.GetToken() }
        })
            .then(response => response.json())
            .then(data => {
                console.log();
                var responseData = data as PeopleSoftCustomerValidationResponse;
                if (responseData.IsSuccess == true) {
                    submitRequestForNonIvr(roleDn, userDn);
                }
                else {
                    setSmAlertMessageTitle("Invalid Request");
                    setSmAlertMessageDesc(responseData.ErrorMessage);
                    setSmAlert(true);
                }
            },
                (error) => {
                    setSmAlertMessageTitle("Error");
                    setSmAlertMessageDesc("Your Request cannot be submitted at this moment");
                    setSmAlert(true);
                });
    }

    function checkIvrForSupportPortal(roleDn: string, userDn: any) {

        const crmContact: CRMSupportPortalContact = {
            firstName: "",
            lastName: "",
            jobTitle: "",
            emailAddress: ""
        }

        const crmPortalObj: CRMSupportPortal = {
            ivr: ivrNumber,
            contact: crmContact,
            origin: "MyCommScope"
        }

        var submitRequestUrl = process.env.REACT_APP_SERVER_URL_POST_CHECK_IVR ? process.env.REACT_APP_SERVER_URL_POST_CHECK_IVR.toString() : ""

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'AUTHORIZATION': 'Bearer ' + token.GetToken() },
            body: JSON.stringify(crmPortalObj)
        };

        fetch(submitRequestUrl, requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log();
                var responseData = data as CRMSupportPortalResponse;
                if (responseData.success == true) {
                    submitRequestForNonIvr(roleDn, userDn);
                }
                else {
                    setSmAlertMessageTitle("Invalid Request");
                    setSmAlertMessageDesc(responseData.customerMessage);
                    setSmAlert(true);
                }
            },
                (error) => {
                    setSmAlertMessageTitle("Error");
                    setSmAlertMessageDesc("Your Request cannot be submitted at this moment");
                    setSmAlert(true);
                });
    }

    function validateAndAssignTechPubs(roleDn: string, userDn: any) {

        const crmContact: CRMSupportPortalContact = {
            firstName: "",
            lastName: "",
            jobTitle: "",
            emailAddress: ""
        }

        const crmPortalObj: CRMSupportPortal = {
            ivr: '',
            contact: crmContact,
            origin: "MyCommScope"
        }

        var submitRequestUrl = process.env.REACT_APP_SERVER_URL_POST_TECH_PUBS_ACCESS ? process.env.REACT_APP_SERVER_URL_POST_TECH_PUBS_ACCESS.toString() : ""

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'AUTHORIZATION': 'Bearer ' + token.GetToken() },
            body: JSON.stringify(crmPortalObj)
        };

        fetch(submitRequestUrl, requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log();
                var responseData = data as CRMSupportPortalResponse;
                if (responseData.success == true) {
                    submitRequestForNonIvr(roleDn, userDn);
                }
                else {
                    setSmAlertMessageTitle("Invalid Request");
                    setSmAlertMessageDesc(responseData.customerMessage);
                    setSmAlert(true);
                }
            },
                (error) => {
                    setSmAlertMessageTitle("Error");
                    setSmAlertMessageDesc("Your Request cannot be submitted at this moment");
                    setSmAlert(true);
                });
    }

    function getCurrentApplicationsData(roleDn: string, userDn: any) {
        var getCurrentAppsUrl = process.env.REACT_APP_SERVER_URL_GET_CURRENT_APPLICATIONS ? process.env.REACT_APP_SERVER_URL_GET_CURRENT_APPLICATIONS.toString() : ""

        const getData = async () =>
            await fetch(getCurrentAppsUrl, {
                headers: { 'AUTHORIZATION': 'Bearer ' + token.GetToken() }
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        var isAppProvisioned = false;
                        if (result.assignments.length > 0) {
                            for (let i = 0; i < result.assignments.length; i++) {
                                if (result.assignments[i].id.toString().toLowerCase().split(/[ ,]+/)[0] == roleDn.toLowerCase().split(/[ ,]+/)[0]) {
                                    isAppProvisioned = true;
                                }
                            }
                        }
                        if (isAppProvisioned == true) {
                            setSmAlertMessageTitle("Request Approved");
                            setSmAlertMessageDesc("Your Request has been approved. Now you can access the application from My CommScope. Please logout from My CommScope and login to see the new Application in your Dashboard.");
                            setSmAlert(true);
                            props.updateFromAvailableApps();
                        }
                        else {
                            setSmAlertMessageTitle("Request Pending");
                            setSmAlertMessageDesc("Your request has been submitted. You can check the status of the request in Pending Requests.");
                            setSmAlert(true);
                            props.updateFromAvailableApps();
                        }
                        //getPendingRequestsData()
                        getAvailableApplicationsDataWithUserDn(userDn);
                    },
                    (error) => {

                    }
                )
        getData();
    }

    function getAvailableApplicationsDataWithUserDn(userDn: any) {

        const getData = async () => {
            var getAvailableAppsUrl = process.env.REACT_APP_SERVER_URL_GET_AVAILABLE_APPLICATIONS ? process.env.REACT_APP_SERVER_URL_GET_AVAILABLE_APPLICATIONS.toString() : ""
            await fetch(getAvailableAppsUrl, {
                headers: { 'AUTHORIZATION': 'Bearer ' + token.GetToken() }
            })
                .then(res =>
                    res.json()
                )
                .then(
                    (result) => {
                        //for (let i = 0; i < result.AvailableApplications.AvailableRoles.length; i++) {
                        //    result.AvailableApplications.AvailableRoles[i].userDn = userDn
                        //}

                        setAvailableApplications(result.AvailableApplications.AvailableRoles)
                        availableAppsRef.current = result.AvailableApplications.AvailableRoles
                        setPendingRequests(pendingRequests)
                        setHideSpinner(true)
                    },
                    (error) => {
                        console.log(error)
                        setHideSpinner(true)
                    }
                )
        }

        getData();


    }


    function getAvailableApplicationsData() {
        var userDn = "";
        getAvailableApplicationsDataWithUserDn(userDn);
    }

    function getData() {
        getAvailableApplicationsData();
    }

    useEffect(() => {
        getData()
    }, [])

    var tempResult: AvailableApplication[] = []

    function updateAvailableAppsData() {
        if (props.pendingRequests !== undefined && props.pendingRequests.length > 0 && availableApplications !== undefined
            && availableApplications.length > 0) {
            let tempApps = availableApplications

            for (let i = 0; i < props.pendingRequests.length; i++) {
                for (let j = 0; j < availableApplications.length; j++) {
                    if (props.pendingRequests[i].applicationName !== null && availableApplications[j].netIQSourceDn !== null && props.pendingRequests[i].applicationName.toLowerCase() === availableApplications[j].netIQSourceDn.toLowerCase()) {
                        tempApps.splice(j, 1)
                    }
                }
            }

            tempResult = []

            for (let i = 0; i < tempApps.length; i++) {
                let tempAvailableApp = new AvailableApplication()
                tempAvailableApp.applicationName = tempApps[i].applicationName
                tempAvailableApp.appUrl = tempApps[i].appUrl
                tempAvailableApp.bundledRole = tempApps[i].bundledRole;
                tempAvailableApp.bundledRolesList = tempApps[i].bundledRolesList;
                tempAvailableApp.category = tempApps[i].category;
                tempAvailableApp.description = tempApps[i].description;
                tempAvailableApp.excludedRole = tempApps[i].excludedRole;
                tempAvailableApp.excludedRolesList = tempApps[i].excludedRolesList;
                tempAvailableApp.id = tempApps[i].id;
                tempAvailableApp.isRequestable = tempApps[i].isRequestable;
                tempAvailableApp.ivrInfo = tempApps[i].ivrInfo;
                tempAvailableApp.learnMore = tempApps[i].learnMore;
                tempAvailableApp.netIQSourceDn = tempApps[i].netIQSourceDn;
                tempAvailableApp.requiresIVR = tempApps[i].requiresIVR;
                tempAvailableApp.roleDn = tempApps[i].roleDn;
                tempAvailableApp.roleDNAppName = tempApps[i].roleDNAppName;
                tempAvailableApp.roleDNLevel = tempApps[i].roleDNLevel;
                tempAvailableApp.supportEmail = tempApps[i].supportEmail;
                tempAvailableApp.userDn = tempApps[i].userDn;
                tempResult.push(tempAvailableApp)
            }

            //setAvailableApplications(tempResult)
            setIsLoaded(true)
        }
    }

    useEffect(() => {
        console.log()

        availableAppsRef.current = []
        availableAppsRef.current = [...tempResult]

        setAvailableApplications([...tempResult])
        

    }, [isLoaded])

    useEffect(() => {
        updateAvailableAppsData()
    }, [props.pendingRequests])

    //useEffect(() => {
    //    updateAvailableAppsData()
    //}, [availableApplications])

    interface PendingRequests {
        applicationName: string,
        applicationDescription: string,
        requestedDate: Date
    }

    const [pendingRequests, setPendingRequests] = useState<Array<PendingRequests>>([])

    function getPendingRequestsData() {
        if (props.userDetails !== undefined && props.userDetails.UserDetails !== undefined) {

            var userDn = props.userDetails.UserDetails.dn;

            const getData = async () => {
                var getPendingRequestsUrl = process.env.REACT_APP_SERVER_URL_GET_PENDING_REQUESTS ? process.env.REACT_APP_SERVER_URL_GET_PENDING_REQUESTS.toString() : ""
                await fetch(getPendingRequestsUrl, {
                    headers: { 'AUTHORIZATION': 'Bearer ' + token.GetToken() }
                })
                    .then(res =>
                        res.json()
                    )
                    .then(
                        (result) => {
                            setAvailableApplications(result)
                            setHideSpinner(true)
                        },
                        (error) => {
                            console.log(error)
                            setHideSpinner(true)
                        }
                    )
            }
            if (userDn !== '' && userDn !== undefined) {
                getData();
            }
        }
    };

    return (
        <div>
            <Modal show={smAlert} onHide={() => setSmAlert(false)}>
                <Modal.Header>
                    <Modal.Title>{smAlertMessageTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{smAlertMessageDesc}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setSmAlert(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            {isDesktopOrLaptop && <div >
                <div className="container">
                    <div className='tableLayout'>
                        <div className="row">
                            <div className="col-md-12">
                                <p className='textContent'>
                                    Select the Request Access button to Request Access
                                </p>
                            </div>
                        </div>
                        <LearnMoreModal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            title="Learn More" description={learnMoreDesc} />
                        <RequestAssistanceModal
                            show={requestAssistanceModalShow}
                            mailTo={requestAssistanceSupportMail}
                            onHide={() => setRequestAssistanceModalShow(false)}
                            title="Request Assistance"
                        />

                        <div className="row">
                            <div className="col-md-12">
                                <GridComponent dataSource={availableApplications} allowTextWrap={true} allowGrouping={true}
                                    groupSettings={groupOptions} id='availableAppsGrid'>
                                    <ColumnsDirective>
                                        <ColumnDirective template={appNameColumnTemplate} headerTextAlign="Left"
                                            headerText='Application / Description'
                                            width='300px' textAlign="Left" />
                                        <ColumnDirective template={ivrColumnTemplate} hideAtMedia="(min-width: 700px)"
                                            field='requiresIVR' headerText="" headerTextAlign="Left" width='140' textAlign="Left" />
                                        <ColumnDirective headerText="Access Status" template={statusColumnTemplate}
                                            headerTextAlign="Left" width='120' textAlign="Left" />
                                        <ColumnDirective field='category' hideAtMedia="(min-width: 700px)" headerText=""
                                            headerTextAlign="Left" width='240' textAlign="Left" />
                                    </ColumnsDirective>
                                    <Inject services={[Group]} />
                                </GridComponent >
                            </div>
                            <div hidden={hideSpinner} className="spinner-border spinner-md text-primary" style={{ marginLeft: '36%', marginTop: '3%', position: 'absolute' }} role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
            {isTabletOrMobile &&
                <ul>
                    <LearnMoreModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        title="Learn More" description={learnMoreDesc} />
                    <RequestAssistanceModal
                        show={requestAssistanceModalShow}
                        mailTo={requestAssistanceSupportMail}
                        onHide={() => setRequestAssistanceModalShow(false)}
                        title="Request Assistance"
                    />
                    {availableApplications.map((temp) => (
                        <div className="card bg-white" style={{ padding: "1rem", marginLeft: '-2rem', marginTop: '1rem' }} key={temp.id}>
                            <h6 className="card-title" style={{ fontWeight: 'bold' }} >{temp.applicationName}</h6>
                            <p className="card-text">{temp.description}</p>
                            {temp.requiresIVR == true &&
                                <div style={{ marginBottom: '1rem' }} >
                                    <div className='tooltipLayout'>
                                        <label>IVR #</label>
                                        <span className='tooltiptext'>IVR Number</span>
                                    </div>
                                    <input type="text" name="name" style={{ marginLeft: '0.3rem', marginRight: '1rem', height: '1.75rem', width: '15rem' }} />
                                </div>
                            }
                            <ButtonComponent cssClass='e-outline' isPrimary
                                style={{ width: '140px', borderColor: 'lightgray', color: 'black' }}
                                id="btnRequestAccess" onClick={(e) => submitRequest(temp.roleDn, e, temp.requiresIVR, temp.userDn)}>Request Access</ButtonComponent>
                        </div>
                    ))}
                    <div hidden={hideSpinner} className="spinner-border spinner-md text-primary" style={{ marginLeft: '33%', marginTop: '3%', position: 'absolute' }} role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </ul>

            }
        </div>);
}
export default AvailableApplications;
