export class AvailableApplication {
    id: number;
    roleDn: string;
    appUrl: string;
    applicationName: string;
    description: string;
    requiresIVR: boolean;
    category: string;
    sourceDn: string;
    userDn: string;
    netIQSourceDn: string;
    bundledRole: string;
    bundledRolesList: [];
    excludedRole: string;
    excludedRolesList: [];
    isRequestable: boolean;
    ivrInfo: string;
    learnMore: string;
    roleDNAppName: string;
    roleDNLevel: string;
    supportEmail: string;

    constructor() {
        this.id = 0;
        this.roleDn = "";
        this.appUrl = "";
        this.applicationName = "";
        this.description = "";
        this.category = "";
        this.requiresIVR = false;
        this.sourceDn = "";
        this.netIQSourceDn = "";
        this.userDn = "";

        this.bundledRole = "";
        this.bundledRolesList = [];
        this.excludedRole = "";
        this.excludedRolesList = [];
        this.isRequestable = false;

        this.ivrInfo = "";
        this.learnMore = "";
        this.roleDNAppName = "";
        this.roleDNLevel = "";
        this.supportEmail = "";
    }
}