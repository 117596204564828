import React from 'react'

function FilledCircle(props:any) {
    var circleStyle = {
        padding: 0,
        margin: 0,
        display: "inline-block",
        backgroundColor: props.bgColor,
        borderRadius: "50%",
        width: 20,
        height: 20,
    };
    return (
        <div style={circleStyle}>
        </div>
    );
}
export default FilledCircle;