import React, { useState, useMemo, useEffect } from 'react'
import FilledCircle from '../GenericComponents/FilledCircle'
import { COLORS } from '../../values/colors';
import { ColumnDirective, ColumnsDirective, GridComponent, Group, GroupSettingsModel, Inject } from '@syncfusion/ej2-react-grids';
import '../../styles/appStyles.css'
import { useMediaQuery } from 'react-responsive';
import ManageToken from '../../Token';
import { setSpinner, createSpinner, showSpinner } from '@syncfusion/ej2-react-popups';
import ColoredLine from './ColoredLine';
import './PendingRequests.css'

function PendingRequestsTable(props: any, pendingRequestsData: any) {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    const groupOptions: GroupSettingsModel = {
        columns: ['Category'],
        showDropArea: false,
        showUngroupButton: false
    };

    interface PendingRequests {
        applicationName: string,
        applicationDescription: string,
        requestedDate: Date
    }

    const [pendingRequests, setPendingRequests] = useState<Array<PendingRequests>>([])
    const [hideSpinner, setHideSpinner] = useState(false)


    const token = new ManageToken();

    function getPendingRequestsData() {
        const getData = async () => {
            var getPendingRequestsUrl = process.env.REACT_APP_SERVER_URL_GET_PENDING_REQUESTS ? process.env.REACT_APP_SERVER_URL_GET_PENDING_REQUESTS.toString() : ""
            await fetch(getPendingRequestsUrl, {
                headers: { 'AUTHORIZATION': 'Bearer ' + token.GetToken() }
            })
                .then(res =>
                    res.json()
                )
                .then(
                    (result) => {
                        setPendingRequests(result)
                        setHideSpinner(true)
                    },
                    (error) => {
                        console.log(error)
                        setHideSpinner(true)
                    }
                )
        }

        getData();
    }

    function getData() {
        getPendingRequestsData();
    }

    useEffect(() => {
        getData();
    }, [props.userDetails])

    useEffect(() => {
        if (props.refreshComponent !== undefined && props.refreshComponent === true) {
            getData();
        }
    }, [props.refreshComponent])

    useEffect(() => {
        if (pendingRequests !== undefined && pendingRequests.length > 0) {
            props.updateFromPendingRequests(pendingRequests)
        }
    }, [pendingRequests])

    function appNameColumnTemplate(props: any): any {
        return (
            <div style={{ marginBottom: '1rem' }}>
                <a><strong>{props.applicationName}</strong></a>
            </div>
        )
    }

    function requestedDateColumnTemplate(props: any): any {
        return (
            <div style={{ marginBottom: '1rem' }}>
                <a><strong>{props.requestedDate}</strong></a>
            </div>
        )
    }

    function statusColumnTemplate(props: any): any {
        return (
            <div className="row" style={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
                <FilledCircle bgColor={COLORS.Yellow} />
                <a style={{ width: '140px' }}>
                    <strong>Requested/Pending</strong></a>
            </div>
        )
    }

    return (
        <div>
            {isDesktopOrLaptop && pendingRequests.length > 0 &&
                <div>
                    <div style={{ backgroundColor: COLORS.LightGray, marginTop: '3%' }}>
                        <ColoredLine color={COLORS.Yellow} />
                        <div style={{ marginLeft: '8rem', marginRight: '8rem' }}>
                            <div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="text-md-start">
                                            <h2 className='title'>Requests Pending</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-12">
                                                <GridComponent dataSource={pendingRequests} allowTextWrap={true}>
                                                    <ColumnsDirective>
                                                        <ColumnDirective field='applicationName' hideAtMedia="(min-width: 700px)" template={appNameColumnTemplate} headerTextAlign="Left" headerText='Application'
                                                            width='180' textAlign="Left" />
                                                        <ColumnDirective field='applicationName' hideAtMedia="(min-width: 700px)" template={requestedDateColumnTemplate} headerTextAlign="Left" headerText='Request Date'
                                                            width='120' textAlign="Left" />
                                                        <ColumnDirective headerText="Access Status" hideAtMedia="(min-width: 700px)" template={statusColumnTemplate} headerTextAlign="Center" width='160' textAlign="Center" />
                                                    </ColumnsDirective>
                                                    <Inject services={[Group]} />
                                                </GridComponent >
                                            </div>
                                            <div hidden={hideSpinner} className="spinner-border text-primary" style={{ marginLeft: '45%', marginTop: '11%', position: 'absolute' }} role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <ColoredLine color={COLORS.Yellow} />
                    </div>
                </div>
            }
            {isTabletOrMobile && pendingRequests.length > 0 &&
                <div>
                    <div style={{ backgroundColor: COLORS.LightGray }}>
                        <ColoredLine color={COLORS.Yellow} />
                        <div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="text-md-start">
                                            <h2 className='title'>Requests Pending</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row" >
                                            <div className="col-12">
                                                <GridComponent dataSource={pendingRequests} allowTextWrap={true} style={{ backgroundColor: COLORS.LightGray }}>
                                                    <ColumnsDirective>
                                                        <ColumnDirective field='applicationName' template={appNameColumnTemplate} headerTextAlign="Left" headerText='Application' />
                                                        <ColumnDirective field='applicationName' template={requestedDateColumnTemplate}
                                                            headerTextAlign="Left" headerText='Request Date'
                                                            textAlign="Left" />
                                                    </ColumnsDirective>
                                                    <Inject services={[Group]} />
                                                </GridComponent >
                                            </div>
                                            <div hidden={hideSpinner} className="spinner-border spinner-md text-primary" style={{ marginLeft: '45%', marginTop: '29%', position: 'absolute' }} role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ColoredLine color={COLORS.Yellow} />
                    </div>
                </div>
            }
        </div>
    );
}
export default PendingRequestsTable
