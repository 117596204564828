import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { Alert, Col, Row } from 'react-bootstrap';
import ManageToken from '../../Token';

function DeleteDomainModal(props: any) {
    const token = new ManageToken();
    const [hideSpinner, setHideSpinner] = useState(true);
    const [alert, setAlert] = useState("")
    const [alertMsg, setAlertMsg] = useState<Array<string>>([])

    const deleteDomain = (event: any) => {
        setHideSpinner(false)
        var deleteDomainUrl = process.env.REACT_APP_SERVER_URL_POST_DOMAIN_MAPPING_DELETE_DOMAIN ? process.env.REACT_APP_SERVER_URL_POST_DOMAIN_MAPPING_DELETE_DOMAIN.toString() : ""

        const request = {
            domain: props.domain,
            roleName: props.roleName,
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'AUTHORIZATION': 'Bearer ' + token.GetToken() },
            body: JSON.stringify(request)
        };

        fetch(deleteDomainUrl, requestOptions)
            .then(response => response.json())
            .then(data => {
                var responseData = data;
                if (responseData.isSuccess === true) {
                    setHideSpinner(true)
                    //props.onHide()
                    setAlert("success")
                    setAlertMsg(responseData.responseMessage)
                    //props.onHide()
                    //props.deleteConfirmation()
                }
                else {
                    setHideSpinner(true)
                    setAlert("danger")
                    setAlertMsg(responseData.responseMessage[0])
                }
            });
    };

    const deleteRole = (event: any) => {
        setHideSpinner(false)
        var deleteRoleUrl = process.env.REACT_APP_SERVER_URL_POST_DOMAIN_MAPPING_DELETE_ROLES ? process.env.REACT_APP_SERVER_URL_POST_DOMAIN_MAPPING_DELETE_ROLES.toString() : ""

        const request = {
            domain: props.domain,
            roleName: props.roleName,
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'AUTHORIZATION': 'Bearer ' + token.GetToken() },
            body: JSON.stringify(request)
        };

        fetch(deleteRoleUrl, requestOptions)
            .then(response => response.json())
            .then(data => {
                var responseData = data;
                if (responseData.isSuccess === true) {
                    setHideSpinner(true)
                    //props.onHide()
                    setAlert("success")
                    
                    setAlertMsg(responseData.responseMessage)
                    //props.onHide()
                    //props.deleteConfirmation()
                }
                else {
                    setHideSpinner(true)
                    setAlert("danger")
                    setAlertMsg(responseData.responseMessage[0])
                }
            });
    };


    function clearForm() {
        setAlert("")
        setAlertMsg([])
        props.onHide()
    }


    return (
        <Modal className=""
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Do you confirm to delete the Domain <b>{props.domain}</b>?</p>

                <div hidden={hideSpinner} className="spinner-border spinner-md text-primary" style={{ marginLeft: '37%', marginTop: '3%', position: 'absolute' }} role="status">
                    <span className="sr-only">Loading...</span>
                </div>
                <div className="row">
                    <div className="col-md-10">
                        <Alert variant={alert} >
                            {alertMsg.map((x) => (
                                <p>{x}</p>
                            ))}</Alert>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={deleteDomain} className="btn btn-primary">Remove Domain</Button>
                <Button onClick={deleteRole} className="btn btn-primary">Remove a Role</Button>
                <Button onClick={clearForm}>Close</Button>
            </Modal.Footer>
        </Modal>

    );
}
export default DeleteDomainModal;