export class Roles {
    id: number;
    roleDn: string;
    roleDNAppName: string;
    roleName: string;
    roleDnLevel: string;
    applicationName: string;
    description: string;
    requiresIVR: boolean;
    ivrInfo: string;
    category: string;
    bundledRole: string;
    excludedRole: string;
    isRequestable: boolean;
    supportEmail: string;
    learnMore: string;
    appUrl: string;
    netIQSourceDn: string;


    constructor() {
        this.id = 0;
        this.roleDn = "";
        this.roleDNAppName = "";
        this.roleName = "";
        this.roleDnLevel = "";
        this.applicationName = "";
        this.description = "";
        this.requiresIVR = false;
        this.ivrInfo = "";
        this.category = "";
        this.bundledRole = "";
        this.excludedRole = "";
        this.isRequestable = false;
        this.supportEmail = "";
        this.learnMore = "";
        this.appUrl = "";
        this.netIQSourceDn = "";
    }
}