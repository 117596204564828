export class DomainMappingSearch {
    domain: string;
    roleName: string;
    modifiedBy: string;
    ModifiedDate: string;
   

    constructor() {        
        this.domain = "";
        this.roleName = "";
        this.modifiedBy = "";
        this.ModifiedDate = "";
    }
}